<template>
  <div class="bg-color">
    <div class="select-box">
      <div class="wrapper">
        <el-card class="box-card">
          <div class="box-line clearfix">
            <div class="text">编程语言:</div>
            <div class="con">
              <el-button
                v-for="(item, index) in queryList.btnLanguageList"
                :key="item.id"
                type="text"
                :class="index === langActive ? 'active' : ''"
                @click="handlerQuery(1, item.id)"
                >{{ item.btnName }}</el-button
              >
            </div>
          </div>
          <div class="box-line clearfix">
            <div class="text">试题阶段:</div>
            <div class="con">
              <el-button
                v-for="(item, index) in queryList.btnStageList"
                :key="item.id"
                type="text"
                :class="index === stepActive ? 'active' : ''"
                @click="handlerQuery(2, item.id)"
                >{{ item.btnName }}</el-button
              >
            </div>
          </div>
          <div class="box-line clearfix">
            <div class="text">难度系数:</div>
            <div class="con">
              <el-button
                v-for="(item, index) in diffLevelList"
                :key="item.id"
                type="text"
                :class="index === diffLevel ? 'active' : ''"
                @click="handlerQuery(3, item.id)"
                >{{ item.btnName }}</el-button
              >
            </div>
          </div>
          <div class="box-line nopadding clearfix">
            <div class="text">关键字:</div>
            <div class="con noborder">
              <el-input
                placeholder="请输入关键词"
                v-model="questionTitle"
                size="small"
                class="search"
                @keydown.enter="handleIconClick"
              >
                <template #suffix> <i class="el-icon-search el-input__icon" @click="handleIconClick"> </i> </template
              ></el-input>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div class="table-list">
      <div class="wrapper">
        <div v-if="listDatas.records && listDatas.records.length > 0">
          <el-table stripe style="width: 100%" :data="listDatas.records">
            <el-table-column prop="langName" label="编程语言" width="150"></el-table-column>
            <el-table-column prop="stepTxt" label="试题阶段" width="120"> </el-table-column>
            <el-table-column prop="questionTitle" label="试题名称" width="120"></el-table-column>
            <el-table-column prop="questionTypeTxt" label="题型"></el-table-column>
            <el-table-column prop="tags" label="试题标签"></el-table-column>
            <el-table-column prop="diffLevel" label="难度系数">
              <template #default="scope">
                <el-rate v-model="scope.row.diffLevel" disabled text-color="#ff9900" score-template="{value}">
                </el-rate>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-button type="text" size="small" class="button" @click="toExamPage(scope.row.id)">答题</el-button>
                <!-- <el-button type="text" size="small" class="button">收藏</el-button> -->
                <el-button type="text" size="small" class="button" @click="putDatas(scope.row.id)">
                  {{ scope.row.isCollect ? '取消收藏' : '收藏' }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="pagination">
            <el-pagination
              @current-change="handleCurrentChange"
              v-model:currentPage="pageNo"
              background
              :page-size="10"
              layout="prev, pager, next, jumper"
              :total="listDatas.total"
              class="page-item"
              v-if="listDatas.records && listDatas.records.length > 0"
            >
            </el-pagination>
          </div>
        </div>
        <el-empty description="暂无数据" v-else></el-empty>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryList: {},
      langActive: 0,
      stepActive: 0,
      diffLevel: 0,
      diffLevelList: [
        { id: 0, btnName: '全部' },
        { id: 1, btnName: '一星' },
        { id: 2, btnName: '二星' },
        { id: 3, btnName: '三星' },
        { id: 4, btnName: '四星' },
        { id: 5, btnName: '五星' },
      ],
      questionTitle: '',
      pageNo: 1,
      listDatas: {},
      langs: {}
    };
  },
  mounted() {
    this.getQueryList();
    this.getDatas();
  },
  methods: {
    // 获取筛选条件列表
    getQueryList() {
      const that = this;
      this.$request.get('/studyLangSteps/queryLangSteps').then(function(response) {
        that.queryList = response.data.data;
        let idx = 0;
        for (let k in that.queryList.btnLanguageList) {
          that.langs[that.queryList.btnLanguageList[k].id] = idx;
          idx++;
        }
      });
    },

    // 获取列表信息
    getDatas: function() {
      const that = this;

      // const langTemp = this.langActive === 0 ? 0 : this.langActive + 1;
      let langTemp = 0;
      for (let k in this.langs) {
        if (this.langs[k] === this.langActive) {
          langTemp = k;
          break;
        }
      }

      this.$request
        .get(
          `/studyQuestion/pageQuestionList?lang=${langTemp}&step=${this.stepActive}&diffLevel=${this.diffLevel}&questionTitle=${this.questionTitle}&pageNo=${this.pageNo}`,
        )
        .then(function(response) {
          const { data = {} } = response.data || {};
          const typeDatas = ['单选', '多选', '填空', '问答题', '编程'];
          const stepDatas = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];
          const langDatas = ['图形化', 'Python'];
          const newDatas = data.records.map((i) => {
            i.questionTypeTxt = typeDatas[i.questionType - 1] || '';
            i.stepTxt = (stepDatas[i.step - 1] || '') + '级';
            // i.langTxt = i.langName;
            return i;
          });
          that.listDatas = { ...data, records: newDatas };
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    // 收藏、取消收藏
    putDatas(id) {
      // 判断是否登录

      if (localStorage.getItem('token') === null) {
        this.$store.commit('setLoginVisible', true);
        return;
      }

      const that = this;
      this.$request
        .post(`/studyQuestionCollect/addQuestionCollect?id=${id}`)
        .then(function() {
          that.getDatas();
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    // 分页操作
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getDatas();
    },

    // 条件筛选
    handlerQuery(line, id) {
      if (line === 1) {
        // id = id === 0 ? id : id - 1;
        this.langActive = this.langs[id]// id;
        console.log("langActive:", this.langActive)
      }

      if (line === 2) {
        this.stepActive = id;
      }

      if (line === 3) {
        this.diffLevel = id;
      }

      this.getDatas();
    },

    // 输入关键字时搜索
    handleIconClick() {
      this.pageNo = 1;
      this.getDatas();
    },

    // 跳转到具体答题页
    toExamPage(id) {
      this.$router.push(`/questionbank/${id}?diffLevel=${this.diffLevel}&questionTitle=${this.questionTitle}`);
    },
  },
};
</script>
<style lang="scss">
.select-box {
  .el-card {
    padding: 3px 18px;
  }
  .el-button {
    padding: 0 20px;
    min-height: 30px;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
  }
  .box-line {
    .el-input {
      width: 295px;
      input {
        width: 295px;
        height: 28px;
        line-height: 28px;
        background-color: $bg-color-purle;
      }
    }
  }
}

.table-list {
  .el-table {
    margin-bottom: 60px;
    .has-gutter th {
      background-color: $bg-color-purle;
      text-align: center;
      font-weight: bold;
      color: $text-color;
      .cell {
        background-color: $bg-color-purle;
        text-align: center;
        font-weight: bold;
      }
    }
    td {
      text-align: center;
      border-bottom: none;
      .el-button {
        width: 78px;
        min-height: 30px;
        height: 30px;
        overflow: hidden;
        color: #fff;
        text-align: center;
      }
      .button {
        background-color: $color-primary;
      }
      .disbutton {
        background-color: #c9c9c9;
      }
    }
  }
}

.pagination {
  text-align: right;
  padding-bottom: 50px;
}
</style>
<style lang="scss" scoped>
@import './index.scss';
</style>
