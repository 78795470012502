<template>
  <!-- <el-carousel indicator-position="outside" height="580px">
    <el-carousel-item v-for="(item, index) in bannerData" :key="item.id" :style="!index ? 'display:block' : 'none'">
      <a :href="item.skipUrl" target="_blank" class="home-banner"><img :src="item.pic" alt=""/></a>
    </el-carousel-item>
  </el-carousel> -->
  <div :style="`overflow: hidden;width: ${windowWidth}px;height:  ${windowHeight}px;`">
    <SwiperCarousel />
  </div>
  <!--logo替换-->
  <div class="box list-box">
   <div class="wrapper">
      <h5 class="left-tab-title">
        热门课程
      </h5>
      <p class="type">图形化</p>
      <el-row class="list" :gutter="30" v-if="scratchDatas && scratchDatas.length > 0">
        <el-col :span="30" v-for="o in scratchDatas" :key="o">
          <list-card :data="o || {}"></list-card>
        </el-col>
      </el-row>
      <el-empty description="暂无数据" v-else></el-empty>
      <p class="type">Python</p>
      <el-row class="list" :gutter="30" v-if="pythonDatas && pythonDatas.length > 0">
        <el-col :span="30" v-for="o in pythonDatas" :key="o">
          <list-card :data="o || {}"></list-card>
        </el-col>
      </el-row>
      <el-empty description="暂无数据" v-else></el-empty>
      <p class="type">机器人</p>
      <el-row class="list" :gutter="30" v-if="robotDatas && robotDatas.length > 0">
        <el-col :span="30" v-for="o in robotDatas" :key="o">
          <list-card :data="o || {}"></list-card>
        </el-col>
      </el-row>
      <el-empty description="暂无数据" v-else></el-empty>
      <p class="type">信息科技</p>
      <el-row class="list" :gutter="30" v-if="infoDatas && infoDatas.length > 0">
        <el-col :span="30" v-for="o in infoDatas" :key="o">
          <list-card :data="o || {}"></list-card>
        </el-col>
      </el-row>
      <el-empty description="暂无数据" v-else></el-empty>
      <p class="type">启迪义教</p>
      <el-row class="list" :gutter="30" v-if="yiDatas && yiDatas.length > 0">
        <el-col :span="30" v-for="o in yiDatas" :key="o">
          <list-card :data="o || {}"></list-card>
        </el-col>
      </el-row>
      <el-empty description="暂无数据" v-else></el-empty>
    </div>
  </div>
  <div class="new-box">
    <div class="wrapper">
      <h5 class="left-tab-title">新闻资讯</h5>
      <el-tabs tab-position="left" class="news-tabs" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="data in newsTab" v-bind:key="data.name" :label="data.label" :name="data.name">
          <div v-if="newDatas && newDatas.length > 0">
            <el-row class="news-list" v-for="item in newDatas" v-bind:key="item.id">
              <el-col :span="18">
                <router-link :to="`/news/${item.id}`" class="txt">{{ item.title }}</router-link>
              </el-col>
              <el-col :span="4" :offset="2"
                ><div class="time">{{ $moment(item.date).format('YYYY-MM-DD') }}</div></el-col
              >
            </el-row>
          </div>
          <el-empty description="暂无数据" v-else></el-empty>
          <router-link :to="`/news?type=${data.name}`" class="more">更多资讯</router-link>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import ListCard from '@/components/public/listCard.vue';
import SwiperCarousel from '@/components/public/SwiperCarousel.vue';
import { CDNURL } from '@/utils/constants';
export default {
  components: {
    ListCard,
    SwiperCarousel,
  },
  data() {
    return {
      newDatas: [],
      scratchDatas: [],
      pythonDatas: [],
      robotDatas: [],
      infoDatas: [],
      yiDatas: [],
      activeName: '1',
      newsTab: [
        { label: '活动资讯', name: '1' },
        { label: '活动通知', name: '2' },
        { label: '活动报道', name: '3' },
      ],
      // 轮播数据
      // bannerData: [],
      windowWidth: 0,
      windowHeight: 0
    };
  },
  setup() {
    return {
      value: 4,
    };
  },
  created() {
    this.getBannerData();
  },
  mounted() {
    this.getNewsData();
    this.getCourseData();
    this.windowWidth = document.body.scrollWidth;
    this.windowHeight = document.body.scrollHeight * 0.21;
    // logo替换
    // this.windowHeight = document.body.scrollHeight * 0.5;
  },
  methods: {
    getNewsData: function() {
      const that = this;
      this.$request
        .get(`/studyInfo/homeNews?type=${this.activeName}`)
        .then(function(response) {
          const { data = [] } = response.data || {};
          that.newDatas = data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取轮播数据
    getBannerData: function() {
      const that = this;
      this.$request
        .get(`/studySlideshow/slideshow`)
        .then(function(response) {
          const { data = [] } = response.data || {};
          that.bannerData = data; // .filter((item, i) => i < 5);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getCourseData: function() {
      const that = this;
      this.$request
        .get(`/studyCourse/homeCourse`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          let scData = data.ScratchList;
          let pyData = data.PythonList;
          let rtData = data.RobotList;
          let ioData = data.InfoList;
          let yiData = data.YiList;

          for (let k in scData) {
            scData[k].pic = CDNURL + scData[k].pic;
          }

          for (let j in pyData) {
            pyData[j].pic = CDNURL + pyData[j].pic;
          }

          for (let m in rtData) {
            rtData[m].pic = CDNURL + rtData[m].pic;
          }

          for (let y in yiData) {
            yiData[y].pic = CDNURL + yiData[y].pic;
          }

          for (let i in ioData) {
            ioData[i].pic = CDNURL + ioData[i].pic;
          }

          that.scratchDatas = scData;
          that.pythonDatas = pyData;
          that.robotDatas = rtData;
          that.infoDatas = ioData;
          that.yiDatas = yiData;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    handleClick(tab, event) {
      this.activeName = tab.props.name;
      this.getNewsData();
    },
    toCourseDetail(id) {
      this.$router.push('/user');
    },
  },
};
</script>
<style lang="scss">
@import 'index.scss';
</style>
