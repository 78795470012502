
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    title: String,
    questionTypeName: String,
    onSave: Function,
  },
})
export default class QuestionDetailHead extends Vue {
  $router: any;
  onSave!: () => {};
  goBack() {
    this.$router.push('/questionbank');
  }
  handleSave() {
    if (this.onSave) {
      this.onSave();
    }
  }
}
