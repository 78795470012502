import request from '../utils/request';

/**
 * 获取题目内容
 * @param id 题目id
 */
export function queryExercisesById(id: number) {
  return request('/studyQuestion/queryExercisesById', {
    params: {
      id,
    },
  });
}

/**
 * 获取上一题目内容
 * @param id 题库id
 * @param lang 语言类型
 * @param step 阶段
 * @param diffLevel 难度等级
 * @param questionTitle 关键字
 */
export function getLastQuestion({
  id,
  lang,
  step,
  diffLevel = 1,
  questionTitle = '',
}: {
  id: any;
  lang: number;
  step: number;
  diffLevel: number;
  questionTitle: string;
}) {
  return request('/studyQuestion/getLastQuestion', {
    params: {
      id,
      lang,
      step,
      diffLevel,
      questionTitle,
    },
  });
}

/**
 * 获取下一题目内容
 * @param id 题库id
 * @param lang 语言类型
 * @param step 阶段
 * @param diffLevel 难度等级
 * @param questionTitle 关键字
 */
export function getNextQuestion({
  id,
  lang,
  step,
  diffLevel = 1,
  questionTitle = '',
}: {
  id: any;
  lang: number;
  step: number;
  diffLevel: number;
  questionTitle: string;
}) {
  return request('/studyQuestion/getNextQuestion', {
    params: {
      id,
      lang,
      step,
      diffLevel,
      questionTitle,
    },
  });
}

/**
 * 获取随机题目内容
 * @param id 题库id
 * @param lang 语言类型
 * @param step 阶段
 * @param diffLevel 难度等级
 * @param questionTitle 关键字
 */
export function randomQuestion({
  lang,
  step,
  diffLevel = 1,
  questionTitle = '',
}: {
  lang: number;
  step: number;
  diffLevel: number;
  questionTitle: string;
}) {
  return request('/studyQuestion/randomQuestion', {
    params: {
      lang,
      step,
      diffLevel,
      questionTitle,
    },
  });
}

/**
 * 获取题目解析
 * @param id 题目id
 */
export function queryAnalysisById(id: number) {
  return request('/studyQuestion/queryAnalysisById', {
    params: {
      id,
    },
  });
}

/**
 * 获取提交历史记录
 * @param id 题目id
 */
export function querySubmitRecordById(id: number) {
  return request('/studySubmitRecord/querySubmitRecordById', {
    params: {
      id,
    },
  });
}

/**
 * 提交答案
 * @param id 题目id
 */
export function addSubmitRecord(id: number, ans: string) {
  return request('/studySubmitRecord/addSubmitRecord', {
    method: 'post',
    params: {
      id,
      ans,
    },
  });
}

/**
 * 删除提交记录
 * @param id 记录id
 */
export function deleteCommitHistory(id: number) {
  return request('/studySubmitRecord/delete', {
    method: 'delete',
    params: {
      id,
    },
  });
}
