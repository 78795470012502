import { createStore } from 'vuex';

export default createStore({
  state: {
    loginVisible: false, // 登录弹窗显示
    // 未读消息
    unreadMsg: {
      totalMsgNum: 0, // 总的
      mineSystemMsgNum: 0, // 系统消息
      mineMsgNum: 0, // 我的消息
    },
  },
  mutations: {
    setLoginVisible(state, data) {
      state.loginVisible = data;
    },
    setUnreadMsg(state, data) {
      state.unreadMsg = data;
    },
  },
  actions: {
    getReadData: function(state, datas) {
      const { data, that } = datas;
      return new Promise((resolve, reject) => {
        that.$request
          .get(`/studyMsg/queryMyMsgNum`)
          .then(function(response: any) {
            const { data = {} } = response.data || {};
            state.state.unreadMsg = data;
          })
          .catch(function(error: any) {
            console.log(error);
          });
      });
    },
  },
  modules: {},
});
