<template>
  <div :class="$style['tabs']">
    <div class="wrapper nav">
      <el-tabs v-model="paramData.type" @tab-click="handleClick">
        <el-tab-pane :label="item.label" :name="item.name" v-for="item in navData" v-bind:key="item.name"></el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <div class="bg">
    <div class="wrapper eaxm" :class="$style['eaxm']">
      <el-card class="filter-box " v-if="paramData.type === '3'">
        <div class="filter-items clearfix">
          <div class="label">关键字：</div>
          <div class="filter-con">
            <el-input
              placeholder="请输入关键词"
              v-model="examName"
              size="small"
              class="search"
              @keydown.enter="handleIconClick"
            >
              <template #suffix> <i class="el-icon-search el-input__icon" @click="handleIconClick"> </i> </template>
            </el-input>
          </div>
        </div>
        <div class="filter-items clearfix" :class="$style['filter-items']">
          <div class="label">类型：</div>
          <div class="filter-con">
            <el-select
              v-model="selectValue"
              placeholder="请选择"
              class="filter-select "
              size="small"
              :class="$style['filter-select']"
              @change="selectChangeHandle"
            >
              <el-option v-for="item in typeOptions" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </div>
        </div>
      </el-card>

      <div class="table-list" v-if="paramData.type === '3'">
        <el-table
          stripe
          style="width: 100%"
          :data="listDatas.records"
          v-if="listDatas.records && listDatas.records.length > 0"
        >
          <el-table-column prop="examName" label="考试名称" width="150"></el-table-column>
          <el-table-column prop="langName" label="编程语言" width="120"></el-table-column>
          <el-table-column prop="stepTxt" label="等级" width="120"></el-table-column>
          <el-table-column prop="typeTxt" label="类型"></el-table-column>
          <el-table-column prop="totalScore" label="总分"></el-table-column>
          <el-table-column prop="passMark" label="及格分"> </el-table-column>
          <el-table-column prop="score" label="我的成绩"> </el-table-column>
          <el-table-column prop="passMark" label="通过情况">
            <template #default="scope">
              <el-tag :type="scope.row.passType === '通过' ? '' : 'danger'" disable-transitions>{{
                scope.row.passType
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button
                type="text"
                size="small"
                class="button"
                @click.prevent="toAnswerPage(scope.row.id, scope.row.answerNum)"
                v-if="scope.row.passType !== '未发布成绩' && scope.row.passType !== '缺考'"
                >查看答案</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-empty description="暂无数据" v-else></el-empty>
      </div>
      <div v-else>
        <exam-card
          :data="listDatas.records"
          :paramData="paramData"
          v-if="listDatas.records && listDatas.records.length > 0"
        ></exam-card>
        <el-empty description="暂无数据" v-else></el-empty>
      </div>

      <el-pagination
        @current-change="handleCurrentChange"
        v-model:currentPage="pageNo"
        background
        :page-size="100"
        layout="prev, pager, next, jumper"
        :total="listDatas.total"
        class="page-item"
        v-if="listDatas.records && listDatas.records.length > 0"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import ExamCard from '@/components/public/examCard.vue';
import qs from 'qs';
export default {
  components: { ExamCard },
  data() {
    return {
      search: '',
      listDatas: [],
      paramData: {
        type: '1',
      },
      navData: [
        // { label: '正式考试', name: '1' },
        // { label: '模拟练习', name: '2' },
        { label: '成绩查询', name: '3' },
      ],
      typeOptions: [
        { label: '考试', id: '1' },
        { label: '模拟', id: '2' },
      ],
      listTableDatas: [],
      pageNo: 1,
      examName: '',
      selectValue: '',
    };
  },
  created() {
    const { query = {} } = this.$route || {};
    this.paramData.type = query.type || '3';
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    getDatas() {
      const that = this;
      const { type } = this.paramData || {};
      let data = {
        queryType: type,
        pageNo: this.pageNo,
      };
      if (type === '3') {
        data = {
          ...data,
          examName: this.examName,
          examType: this.selectValue,
        };
      }
      this.$request
        .get(`/studyExam/queryMyExam?${qs.stringify(data)}`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          const typeDatas = ['考试', '模拟'];
          const stepDatas = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];
          let newDatas = [];
          if (type === '3') {
            newDatas = data.records.map((i) => {
              i.typeTxt = typeDatas[i.type - 1] || '';
              i.stepTxt = (stepDatas[i.step - 1] || '') + '级';
              return i;
            });
          } else {
            newDatas = data.records;
          }
          that.listDatas = { ...data, records: newDatas };
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    handleClick(tab) {
      this.paramData.type = tab.props.name;
      this.pageNo = 1;
      this.examName = '';
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getDatas();
    },
    handleIconClick() {
      this.pageNo = 1;
      this.getDatas();
    },
    selectChangeHandle(v) {
      this.pageNo = 1;
      this.getDatas();
    },
    // 跳到详情
    toAnswerPage(id, answer) {
      this.$router.push(`/exam/done/${id}/exampaper?answerNum=${answer}`);
    },
  },
};
</script>
<style lang="scss" module>
@import './index.scss';
</style>
