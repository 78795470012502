<template>
  <swiper
    :slides-per-view="1"
    :space-between="50"
    :pagination="{ clickable: true }"
    :initialSlide="1"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    style="height:580px"
    :autoplay="{ delay: 5000 }"
    lazy
    :navigation="{
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    }"
    :loop="true"
    :width="winW"
  >
    <swiper-slide v-for="item in bannerData" :key="item.id">
      <a :href="item.skipUrl" target="_blank" class="home-banner"><img :src="CDNURL + item.pic" alt=""/></a>
    </swiper-slide>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </swiper>
</template>
<script>
import { CDNURL } from '@/utils/constants';
// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, A11y, Autoplay, Lazy } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

// install Swiper components
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, Lazy]);

// Import Swiper styles
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      // 轮播数据
      bannerData: [],
      winW: 0,
      CDNURL: CDNURL,
      // swiperOption: {
      //   autoplay: 3000,
      //   loop: true, // 前后衔接循环
      // },
    };
  },
  created() {
    this.getBannerData();
    this.winW = window.screen.width;
  },
  methods: {
    // 获取轮播数据
    getBannerData: function() {
      const that = this;
      this.$request
        .get(`/studySlideshow/slideshow`)
        .then(function(response) {
          const { data = [] } = response.data || {};
          that.bannerData = data.filter((item, i) => i < 5);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    onSwiper(swiper) {
      // console.log(swiper)
    },
    onSlideChange() {
      // console.log('slide change')
    },
  },
};
</script>

<style scoped lang="scss">
.swiper-container {
  width: 100%;
  overflow: hidden;
}
</style>
<style lang="scss">
@import 'swiper/components/navigation/navigation.scss';
@import 'swiper/components/pagination/pagination.scss';
</style>
