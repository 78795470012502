<template>
  <div class="bread-crumb">
    <el-breadcrumb separator="/" class="wrapper">
      <el-breadcrumb-item :to="{ path: '/news' }">资讯</el-breadcrumb-item>
      <el-breadcrumb-item>资讯详情</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
  <div class="bg">
    <div class="wrapper">
      <el-card :class="$style['news-detail']">
        <article :class="$style['article']">
          <h2>{{ detailData.title }}</h2>
          <p :class="$style['time']">{{ $moment(detailData.date).format('YYYY-MM-DD HH:mm') }}</p>
          <div v-html="detailData.content"></div>
        </article>
      </el-card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 参数
      paramData: {
        id: '',
      },
      // 文章数据
      detailData: {},
    };
  },
  created() {
    const { params = {} } = this.$route || {};
    this.paramData.id = params.id;
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    getDatas() {
      const { id } = this.paramData;
      const that = this;
      this.$request
        .get(`/studyInfo/queryInfoById?id=${id}`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          that.detailData = data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss" module>
@import './index.scss';
</style>
