
import QuestionDetailHead from '@/components/panels/QuestionDetailHead.vue';
import SingleChoice from '@/components/questions/SingleChoice.vue';
import MultipleChoice from '@/components/questions/MultipleChoice.vue';
import Completion from '@/components/questions/Completion.vue';
import QA from '@/components/questions/QA.vue';
import Program from '@/components/questions/Program.vue';
import AnswerAnalysis from '@/components/panels/AnswerAnalysis.vue';
import CommitHistory from '@/components/panels/CommitHistory.vue';
import AnswerPageFoot from '@/components/panels/AnswerPageFoot.vue';
import ProgramPanel from '@/components/panels/Program.vue';
import * as questionBankService from '@/services/questionBank';

import { Options, Vue } from 'vue-class-component';
import { ElMessage } from 'element-plus';

@Options({
  props: {},
  components: {
    QuestionDetailHead,
    SingleChoice,
    MultipleChoice,
    Completion,
    QA,
    Program,
    AnswerAnalysis,
    CommitHistory,
    AnswerPageFoot,
    ProgramPanel,
  },
})
export default class QuestionDetail extends Vue {
  $route: any;
  $store: any;
  questionContent: ObjectT = {};
  questionBankId: any = 0;
  langNameMap = ['图形化编程', 'Python'];
  langStepNumMap = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];
  questionTypeNameMap = ['单选题', '多选题', '填空题', '问答题', '编程题'];
  analysisData: ObjectT = {}; // 当前获取的题目解析数据
  historyList: ObjectT[] = []; // 当前获取的历史记录数据
  showAnalysis = false; // 是否显示题目解析内容
  showHistory = false; // 是否显示提交历史内容
  currAnalysisId = 0; // 当前解析对应的题目id
  currHistoryId = 0; // 当前提交记录对应的题目id
  answerContent = ''; // 要提交的答案
  title = ''; // 标题
  isLogin = !!localStorage.getItem('token'); // 是否登录
  diffLevel = 0; // 难度等级
  questionTitle = ''; // 关键词

  created() {
    this.questionBankId = this.$route.params.id;
    const diffLevel = this.$route.query.diffLevel;
    const questionTitle = this.$route.questionTitle;
    if (diffLevel) {
      this.diffLevel = diffLevel;
    }
    if (questionTitle) {
      this.questionTitle = questionTitle;
    }
  }
  mounted() {
    this.getQuestionContent();
  }
  // 处理题目答题变更
  handleAnswerChange(type: number, totalSort: number, value: any) {
    console.log('@@@@@@@@@', type);
    switch (type) {
      case 1:
        this.answerContent = value;
        break;
      case 2:
        this.answerContent = value.join('|');
        break;
      case 3: {
        const newValue = value.map((item: string) => {
          return item.trim();
        });
        this.answerContent = newValue.join('|');
        break;
      }
      case 4:
        this.answerContent = value.trim();
        break;
      case 5:
        this.answerContent = value;
        console.log('@@@@@@@@@');
        this.handleAnswerSubmit();
        break;
      default:
        break;
    }
  }
  // 处理答案提交
  async handleAnswerSubmit() {
    if (!this.checkLogin()) {
      return;
    }
    const resp = await questionBankService.addSubmitRecord(this.questionContent.id, this.answerContent);
    const data = resp.data;
    if (!data.code && this.questionContent.questionType !== 5) {
      ElMessage.success(data.msg ? data.msg : '提交成功');
      if (this.showHistory) {
        this.getSubmitRecord();
      }
    }
  }

  checkLogin() {
    if (!this.isLogin) {
      // 未登录
      ElMessage.error('请先登录');
      this.showLoginPop();
      return false;
    }
    return true;
  }

  // 显示登录弹窗
  showLoginPop() {
    this.$store.commit('setLoginVisible', true);
  }

  // 获取题目详情
  async getQuestionContent() {
    const resp = await questionBankService.queryExercisesById(this.questionBankId);
    const data = resp.data;
    if (data.code) {
      return;
    }
    this.questionContent = data.data;
    this.title = this.questionContent.langName + this.langStepNumMap[this.questionContent.step - 1] + '级'; // this.langNameMap[this.questionContent.lang - 1] + this.langStepNumMap[this.questionContent.step - 1] + '级';
  }
  // 获取上一题
  async getPrevQuestion() {
    const resp = await questionBankService.getLastQuestion({
      id: this.questionContent.id,
      lang: this.questionContent.lang,
      step: this.questionContent.step,
      diffLevel: this.diffLevel,
      questionTitle: this.questionTitle,
    });
    const data = resp.data;
    if (data.code) {
      return;
    }
    this.questionContent = data.data;
  }
  // 获取下一题
  async getNextQuestion() {
    const resp = await questionBankService.getNextQuestion({
      id: this.questionContent.id,
      lang: this.questionContent.lang,
      step: this.questionContent.step,
      diffLevel: this.diffLevel,
      questionTitle: this.questionTitle,
    });
    const data = resp.data;
    if (data.code) {
      return;
    }
    this.questionContent = data.data;
  }
  // 获取随机题目
  async getRandomQuestion() {
    const resp = await questionBankService.randomQuestion({
      lang: this.questionContent.lang,
      step: this.questionContent.step,
      diffLevel: this.diffLevel,
      questionTitle: this.questionTitle,
    });
    const data = resp.data;
    if (data.code) {
      return;
    }
    this.questionContent = data.data;
  }
  // 处理点击查看题目解析
  async handleViewAnalysis() {
    this.showAnalysis = !this.showAnalysis;
    if (!this.showAnalysis) {
      return;
    }
    const resp = await questionBankService.queryAnalysisById(this.questionContent.id);
    const data = resp.data;
    this.analysisData = data.data;
  }
  // 处理点击查看题目解析
  async handleViewHistory() {
    this.showHistory = !this.showHistory;
    if (!this.showHistory) {
      return;
    }
    this.getSubmitRecord();
  }

  // 获取历史提交记录
  async getSubmitRecord() {
    const resp = await questionBankService.querySubmitRecordById(this.questionContent.id);
    const data = resp.data;
    this.historyList = data.data;
  }
  // 处理点击随机一题
  handleRandomQuestion() {
    this.getRandomQuestion();
    this.showAnalysis = false;
    this.showHistory = false;
  }

  // 处理点击上一题
  handlePrevQuestion() {
    this.getPrevQuestion();
    this.showAnalysis = false;
    this.showHistory = false;
  }
  // 处理点击下一题
  handleNextQuestion() {
    this.getNextQuestion();
    this.showAnalysis = false;
    this.showHistory = false;
  }
  // 处理历史记录删除
  async handleDeleteCommitHistory(id: number) {
    const resp = await questionBankService.deleteCommitHistory(id);
    const data = resp.data;
    if (data.code) {
      return;
    }
    ElMessage.success(data.msg);
    this.getSubmitRecord();
  }
}
