<template>
  <div :class="$style['tabs']">
    <div class="nav wrapper">
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane :label="item.label" :name="item.name" v-for="item in navData" v-bind:key="item.name"></el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <div class="bg">
    <div class="wrapper">
      <el-card class="filter-box ">
        <div class="filter-items clearfix">
          <div class="label">关键字：</div>
          <div class="filter-con">
            <el-input
              placeholder="请输入关键词"
              v-model="questionTitle"
              size="small"
              class="search"
              @keydown.enter="handleIconClick"
            >
              <template #suffix> <i class="el-icon-search el-input__icon" @click="handleIconClick"> </i> </template
            ></el-input>
          </div>
        </div>
      </el-card>

      <div class="table-list">
        <el-table
          stripe
          style="width: 100%"
          :data="listDatas.records"
          v-if="listDatas.records && listDatas.records.length > 0"
        >
          <el-table-column prop="langName" label="编程语言" width="150"></el-table-column>
          <el-table-column prop="stepTxt" label="试题阶段" width="120"></el-table-column>
          <el-table-column prop="questionTitle" label="试题名称" width="120"></el-table-column>
          <el-table-column prop="questionTypeTxt" label="题型"></el-table-column>
          <el-table-column prop="tags" label="试题标签"></el-table-column>
          <el-table-column prop="diffLevel" label="难度系数">
            <template #default="scope">
              <el-rate v-model="scope.row.diffLevel" disabled text-color="#ff9900" score-template="{value}"> </el-rate>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button type="text" size="small" class="button" @click="toExamPage(scope.row.id)">答题</el-button>
              <el-button type="text" size="small" class="button" @click="putDatas(scope.row.id)">{{
                scope.row.isCollect ? '取消收藏' : '收藏'
              }}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-empty description="暂无数据" v-else></el-empty>
      </div>
      <el-pagination
        @current-change="handleCurrentChange"
        v-model:currentPage="pageNo"
        background
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="listDatas.total"
        class="page-item"
        v-if="listDatas.records && listDatas.records.length > 0"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: '1',
      pageNo: 1,
      navData: [
        { label: '收藏的题', name: '1' },
        { label: '做过的题', name: '2' },
      ],
      questionTitle: '',
      listDatas: {},
    };
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    // 获取数据
    getDatas() {
      const that = this;
      this.$request
        .get(
          `/studyQuestion/queryMyCollect?type=${this.activeName}&pageNo=${this.pageNo}&questionTitle=${this.questionTitle}`,
        )
        .then(function(response) {
          const { data = {}, code } = response.data || {};
          if (code === 0) {
            const typeDatas = ['单选', '多选', '填空', '问答题', '编程'];
            const stepDatas = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];
            const langDatas = ['图形化', 'Python'];
            const newDatas = data.records.map((i) => {
              i.questionTypeTxt = typeDatas[i.questionType - 1] || '';
              i.stepTxt = (stepDatas[i.step - 1] || '') + '级';
              // i.langTxt = langDatas[i.lang - 1] || '';
              return i;
            });
            that.listDatas = { ...data, records: newDatas };
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 收藏操作
    putDatas(id) {
      const that = this;
      this.$request
        .post(`/studyQuestionCollect/addQuestionCollect?id=${id}`)
        .then(function(response) {
          const { code } = response.data || {};
          if (code === 0) {
            that.getDatas();
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getDatas();
    },
    handleTabClick(tab) {
      this.activeName = tab.props.name;
      this.pageNo = 1;
      this.questionTitle = '';
      this.getDatas();
    },
    handleIconClick() {
      this.pageNo = 1;
      this.getDatas();
    },
    // 跳到详情
    toExamPage(id) {
      this.$router.push(`/questionbank/${id}`);
    },
  },
};
</script>
<style lang="scss" module>
@import 'index.scss';
</style>
