import request from '../utils/request';

export interface SubmitAnswerDataType {
  questionId: string | number;
  answer: string;
  questionType: number;
  examPaperId?: string | number;
  studentId: string | number;
}

/**
 * 获取作业内容
 * @param examId 考试id
 */
export function startDoHomework(examPaperId: number) {
  return request('/studyStudentWork/startDoHomework', {
    params: {
      examPaperId,
    },
  });
}

/**
 * 作业保存/提交
 */
export function submit(type: number, answer: SubmitAnswerDataType[]) {
  return request('/studyStudentWork/submit', {
    method: 'post',
    data: {
      type,
      answer,
    },
  });
}

/**
 * 作业查询
 */
export function rightAnswers(studentWorkId: string | number) {
  return request('/studyStudentWork/rightAnswers', {
    params: {
      studentWorkId,
    },
  });
}
