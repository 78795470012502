
import { Options, Vue } from 'vue-class-component';
import Header from '@/components/public/header.vue'; // @ is an alias to /src
import Footer from '@/components/public/footer.vue'; // @ is an alias to /src

@Options({
  components: {
    Header,
    Footer,
  },
})
export default class APP extends Vue {}
