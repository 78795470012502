
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    title: String,
    wrapper: Boolean,
    goBack: Function,
  },
})
export default class AnswerHead extends Vue {
  goBack!: () => {};

  // 处理返回
  handleGoBack() {
    if (this.goBack) {
      this.goBack();
    }
  }
}
