
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    type: Number,
    showQuestion: Boolean,
    showAnalysis: Boolean,
    showHistory: Boolean,
    onViewQuestion: Function,
    onViewAnalysis: Function,
    onViewHistory: Function,
    onRandom: Function,
    onPrev: Function,
    onNext: Function,
  },
})
export default class AnswerHead extends Vue {
  type!: number;
  onViewQuestion?: () => {};
  onViewAnalysis?: () => {};
  onViewHistory?: () => {};
  onRandom?: () => {};
  onPrev?: () => {};
  onNext?: () => {};

  // 处理点击查看题目
  handleViewQuestion() {
    if (this.onViewQuestion) {
      this.onViewQuestion();
    }
  }
  //  处理点击查看题目解析
  handleViewAnalysis() {
    if (this.onViewAnalysis) {
      this.onViewAnalysis();
    }
  }
  // 处理点击提交历史
  handleViewHistory() {
    if (this.onViewHistory) {
      this.onViewHistory();
    }
  }
  // 处理点击随机一题
  handleRandom() {
    if (this.onRandom) {
      this.onRandom();
    }
  }
  // 处理点击上一题
  handlePrev() {
    if (this.onPrev) {
      this.onPrev();
    }
  }
  // 处理点击下一题
  handleNext() {
    if (this.onNext) {
      this.onNext();
    }
  }
}
