import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home/index.vue';
import About from '../views/About/index.vue';
import Exam from '../views/Exam/index.vue';
import Course from '../views/Course/index.vue';
import QuestionPaper from '../views/QuestionPaper/index.vue';
import QuestionBank from '../views/QuestionBank/index.vue';
import ExamDetail from '../views/Exam/detail.vue';
import UserLayout from '../views/User/layout.vue';
import UserCreate from '../views/User/create.vue';
import UserCourse from '../views/User/course.vue';
import UserQuestion from '../views/User/question.vue';
import UserExam from '../views/User/exam.vue';
import UserInfo from '../views/User/index.vue';
import Message from '../views/Message/index.vue';
import QuestionDetail from '../views/QuestionBank/detail.vue';
import News from '../views/News/index.vue';
import NewsDetail from '../views/News/detail.vue';
import CourseDetail from '../views/Course/detail.vue';
import CourseVideo from '../views/Course/video.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/exam',
    name: 'Exam',
    component: Exam,
    // children: [],
  },
  {
    path: '/exam/:id',
    name: 'ExamDetail',
    component: ExamDetail,
  },
  {
    path: '/exam/:id/exampaper',
    name: 'ExamPaper',
    component: QuestionPaper,
    props: {
      isExam: true,
    },
  },
  {
    path: '/exam/done/:id/exampaper',
    name: 'ExamPaperDone',
    component: QuestionPaper,
    props: {
      isExamAnswer: true,
    },
  },
  {
    path: '/user',
    component: UserLayout,
    children: [
      {
        path: 'create',
        name: 'UserCreate',
        component: UserCreate,
      },
      {
        path: 'course',
        name: 'UserCourse',
        component: UserCourse,
      },
      {
        path: 'question',
        name: 'UserQuestion',
        component: UserQuestion,
      },
      {
        path: 'exam',
        name: 'UserExam',
        component: UserExam,
      },
      {
        path: '',
        name: 'UserInfo',
        component: UserInfo,
      },
    ],
  },
  {
    path: '/course',
    name: 'Course',
    component: Course,
  },
  {
    path: '/course/:id',
    name: 'CourseDetail',
    component: CourseDetail,
  },
  {
    path: '/course/video',
    name: 'CourseVideo',
    component: CourseVideo,
  },
  {
    path: '/course/homework/:id',
    name: 'CourseHomework',
    component: QuestionPaper,
  },
  {
    path: '/course/homework/done/:id',
    name: 'CourseHomeworkDone',
    component: QuestionPaper,
    props: {
      isHomeworkAnswer: true,
    },
  },
  {
    path: '/questionbank',
    name: 'QuestionBank',
    component: QuestionBank,
  },
  {
    path: '/message',
    name: 'Message',
    component: Message,
  },
  {
    path: '/questionbank/:id',
    name: 'QuestionDetail',
    component: QuestionDetail,
  },
  {
    path: '/news',
    name: 'News',
    component: News,
  },
  {
    path: '/news/:id',
    name: 'NewsDetail',
    component: NewsDetail,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
