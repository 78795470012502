
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    onChange: Function,
    quesData: Object,
    showAnswer: Boolean,
  },
  watch: {
    quesData() {
      this.content = '';
    },
  },
})
export default class QA extends Vue {
  content = '';
  quesData: ObjectT = {};
  showAnswer!: boolean;
  onChange!: (type: number, totalSort: number, value: string) => {};
  created() {
    if (this.showAnswer) {
      this.content = this.quesData.studentAnswer ? this.quesData.studentAnswer : '';
    } else {
      this.content = this.quesData.answer ? this.quesData.answer : '';
    }
  }
  // 处理内容change事件
  handleContentInput() {
    if (this.onChange) {
      this.onChange(4, this.quesData.totalSort, this.content);
    }
  }
}
