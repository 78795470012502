
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    onChange: Function,
    quesData: Object,
    showAnswer: Boolean,
  },
  watch: {
    quesData() {
      this.checkList = [];
    },
  },
})
export default class MultipleChoice extends Vue {
  quesData: ObjectT = {};
  onChange!: (type: number, totalSort: number, value: string) => {};
  showAnswer!: boolean;
  checkList = [];
  rightAnswer = '';
  created() {
    if (this.showAnswer) {
      this.checkList = this.quesData.studentAnswer ? this.quesData.studentAnswer.split('|') : [];
    } else {
      this.checkList = this.quesData.answer ? this.quesData.answer.split('|') : [];
    }
    this.rightAnswer = this.quesData.rightAnswer ? this.quesData.rightAnswer.split('|').join('') : '';
  }
  // 处理选项change
  handleOptionChange(value: string) {
    if (this.showAnswer) {
      this.checkList = this.quesData.studentAnswer ? this.quesData.studentAnswer.split('|') : [];
      return;
    }
    if (this.onChange) {
      this.onChange(2, this.quesData.totalSort, value);
    }
  }
}
