import { createApp } from 'vue';
import ElementPlus from 'element-plus';
// import 'element-plus/lib/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn';
import App from './App.vue';
import router from './router';
import store from './store';
import request from './utils/request';
import svgIcon from './components/SvgIcon.vue';
import moment from 'moment';
import './element-variables.scss';
import './theme/css/base.css';
import './theme/scss/common.scss';
import './assets/iconfont/iconfont.js';
// import VideoPlayer from 'vue-video-player';
// import 'vue-video-player/src/custom-theme.css';
// import 'video.js/dist/video-js.css';
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player';
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css';

const newApp = createApp(App);
newApp.config.globalProperties.$request = request;
newApp.config.globalProperties.$moment = moment;
newApp.component('svg-icon', svgIcon);
newApp
  .use(Vue3VideoPlayer, {
    lang: 'zh-CN',
  })
  .use(ElementPlus, { locale })
  .use(store)
  .use(router)
  .mount('#app');
