import axios from 'axios';
import { ElMessage } from 'element-plus';
import store from '../store';

const request: any = axios.create({
  // baseURL: 'http://42.193.182.81:9091/api',
  baseURL: 'https://api.ideacode.com.cn/api',
  // baseURL: 'http://127.0.0.1:9091/api',
  timeout: 50000,
});

// request拦截器请求拦截配置
request.interceptors.request.use(
  (config: ObjectT) => {
    config.headers = {
      ...config.headers,
      Authorization: localStorage.getItem('token') || '',
      domain: document.domain
    };
    return config;
  },
  (error: ObjectT) => {
    console.error('error:', error);
    return Promise.reject(error);
  },
);

request.interceptors.response.use(
  (resp: ObjectT) => {
    const { data } = resp;
    if (data.code === 10002) {
      localStorage.clear();
      window.location.reload();
    } else if (data.code === 10001) {
      store.commit('setLoginVisible', true);
    } else if (data.code) {
      ElMessage.error(data.msg);
    }
    return resp;
  },
  (error: ObjectT) => {
    console.error('error:', error);
    return Promise.reject(error);
  },
);

export default request;
