<template>
  <div :class="$style['header']">
    <div :class="$style['search']">
      <el-input
        v-model="search"
        placeholder="请输入内容"
        :class="$style['input']"
        size="small"
        @keydown.enter="searchHandler"
      ></el-input>
      <el-button type="primary" :class="$style['search-btn']" size="small" @click="searchHandler">搜索</el-button>
    </div>
    <div class="wrapper nav">
      <el-tabs v-model="paramData.type" @tab-click="handleClick">
        <el-tab-pane label="正式考试" name="1"></el-tab-pane>
        <el-tab-pane label="模拟练习" name="2"></el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <div class="bg">
    <div class="wrapper eaxm" :class="$style['eaxm']">
      <exam-card
        :data="listDatas.records"
        :paramData="paramData"
        v-if="listDatas.records && listDatas.records.length > 0"
      ></exam-card>
      <el-empty description="暂无数据" v-else></el-empty>
      <el-pagination
        @current-change="handleCurrentChange"
        v-model:currentPage="currentPage"
        background
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="listDatas.total"
        class="page-item"
        v-if="listDatas.records && listDatas.records.length > 0"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import ExamCard from '@/components/public/examCard.vue';
export default {
  components: { ExamCard },
  data() {
    return {
      search: '',
      activeName: 'first',
      listDatas: {
        records: [],
        total: 0,
      },
      currentPage: 1,
      paramData: {
        type: '1',
      },
    };
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    getDatas() {
      const that = this;
      const { type } = this.paramData || {};
      this.$request
        .get(`/studyExam/pageExamList?type=${type}&pageNo=${this.currentPage}&examName=${this.search}`)
        .then(function(response) {
          that.listDatas = response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    handleClick(tab) {
      this.paramData.type = tab.props.name;
      this.currentPage = 1;
      this.search = '';
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDatas();
    },
    searchHandler() {
      this.currentPage = 1;
      this.getDatas();
    },
  },
};
</script>
<style lang="scss">
.eaxm {
  .el-card__body {
    padding: 7px;
  }
  .el-card {
    border-radius: 10px;
  }
}
</style>
<style lang="scss" module>
@import './index.scss';
</style>
