
import { Options, Vue } from 'vue-class-component';
import moment from 'moment';
import { PROGRAMURL } from '@/utils/constants';

@Options({
  props: {
    type: Number, // 1=单选；2=多选；3=填空；4=问答；5=编程
    historyData: Array,
    onDelete: Function,
    lang: Number,
  },
  computed: {
    date() {
      return (date: Date) => {
        return moment(date).format('YYYY/MM/DD HH:mm');
      };
    },
    newData() {
      const newData: ObjectT[] = [];
      if (this.historyData && this.historyData.length > 0) {
        this.historyData.map((item: ObjectT) => {
          newData.push({
            ...item,
            ans: item.ans.split('|'),
          });
        });
      }
      return newData;
    },
  },
})
export default class CommitHistory extends Vue {
  $confirm: any;
  type = 0;
  checkList = true;
  lang!: number;
  PROGRAMURL = PROGRAMURL;
  token = localStorage.getItem('token');
  onDelete!: (id: number) => {};
  // 处理复选框切换
  handleCheckboxChange() {
    this.checkList = true;
  }
  // 处理历史记录删除
  handleDelete(id: number) {
    this.$confirm('确认要删除该条提交记录吗？', '提示')
      .then(() => {
        if (this.onDelete) {
          this.onDelete(id);
        }
      })
      .catch(() => {
        // 取消
      });
  }

  // 获取编程题类型名称
  getLangName() {
    let langName = '';
    switch (this.lang) {
      case 2:
        langName = '图形化';
        break;
      case 3:
        langName = 'python';
        break;
      default:
        break;
    }
    return langName;
  }
  // 查看代码
  handleViewCode(ans: string) {
    const langName = this.getLangName();
    const url = `${PROGRAMURL}${langName}/?token=${this.token}${ans ? `&codeUrl=${ans}` : ''}`;
    window.open(url, '_blank');
  }
}
