
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    onChange: Function,
    quesData: Object,
    showAnswer: Boolean,
  },
  watch: {
    quesData() {
      this.completionList = [];
    },
  },
})
export default class Completion extends Vue {
  quesData: ObjectT = {};
  onChange!: (type: number, totalSort: number, value: string[]) => {};
  showAnswer!: boolean;
  completionList: string[] = [];
  rightAnswer: string[] = [];
  created() {
    if (this.showAnswer) {
      this.completionList = this.quesData.studentAnswer ? this.quesData.studentAnswer.split('|') : [];
    } else {
      this.completionList = this.quesData.answer ? this.quesData.answer.split('|') : [];
    }

    this.rightAnswer = this.quesData.rightAnswer ? this.quesData.rightAnswer.split('|') : [];
  }
  mounted() {
    this.initCompletionList();
  }
  // 初始化填空数据
  initCompletionList() {
    for (let i = 0; i < this.quesData.blank; i++) {
      this.completionList[i] = this.completionList[i] ? this.completionList[i] : '';
    }
  }
  // 处理内容change事件
  handleContentInput() {
    if (this.onChange) {
      this.onChange(3, this.quesData.totalSort, this.completionList);
    }
  }
}
