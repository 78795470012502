<template>
  <div :class="$style['tabs']">
    <div class="nav wrapper">
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane :label="item.label" :name="item.name" v-for="item in navData" v-bind:key="item.name"></el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <div class="bg">
    <div class="wrapper">
      <el-card class="filter-box ">
        <div class="filter-items clearfix" v-if="activeName === '4'">
          <div class="label">课程：</div>
          <el-select
            v-model="courseId"
            placeholder="请选择"
            class="filter-select"
            size="small"
            @change="selectChangeHandle"
          >
            <el-option v-for="item in selectOptions" :key="item.id" :label="item.courseName" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="filter-items clearfix" v-else>
          <div class="label">课程类型：</div>
          <el-radio-group v-model="radioValue" class="filter-item" size="small" @change="radioChangeHandle">
            <el-radio-button label="0">全部</el-radio-button>
            <el-radio-button label="2">图形化</el-radio-button>
            <el-radio-button label="3">Python</el-radio-button>
            <el-radio-button label="4">机器人</el-radio-button>
            <el-radio-button label="5">信息科技</el-radio-button>
            <el-radio-button label="6">启迪义教</el-radio-button>
          </el-radio-group>
        </div>
      </el-card>
      <div class="table-list" v-if="activeName === '9'">
        <el-table
          stripe
          style="width: 100%"
          :data="listDatas.records"
          v-if="listDatas.records && listDatas.records.length > 0"
        >
          <el-table-column prop="numId" label="编号" width="50"></el-table-column>
          <el-table-column prop="courseName" label="课程名称" width="170"></el-table-column>
          <el-table-column prop="lessonName" label="章节名称" width="170"></el-table-column>
          <el-table-column prop="examName" label="作业名称" width="170"></el-table-column>
          <el-table-column prop="submitTime" label="提交时间" width="170"></el-table-column>
          <el-table-column prop="updateTime" label="修改时间" width="170"></el-table-column>
          <el-table-column prop="score" label="分数" width="100"> </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button type="text" size="small" class="button" @click="toWorkPage(scope.row)">{{
                scope.row.status === 2 ? '查看答案' : '修改作业'
              }}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-empty description="暂无数据" v-else></el-empty>
      </div>
      <div class="list-box" :class="$style['create-list']" v-else>
        <el-row
          class="list"
          :gutter="30"
          :class="$style['list']"
          v-if="listDatas.records && listDatas.records.length > 0"
        >
          <el-col :span="30" v-for="o in listDatas.records" :key="o">
            <list-card :data="o"></list-card>
          </el-col>
        </el-row>
        <el-empty description="暂无数据" v-else></el-empty>
      </div>
      <el-pagination
        @current-change="handleCurrentChange"
        v-model:currentPage="pageNo"
        background
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="listDatas.total"
        class="page-item"
        v-if="listDatas.records && listDatas.records.length > 0"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import ListCard from '@/components/public/listCard.vue';
import { CDNURL } from '@/utils/constants';
export default {
  components: { ListCard },
  data() {
    ListCard;
    return {
      pageNo: 1,
      activeName: '1', // tab 选中
      radioValue: '0', // 标签选中
      // tab 数据
      navData: [
        { label: '直播课程', name: '1' },
        { label: '视频课程', name: '2' },
        { label: '双师课程', name: '3' },
        { label: '我的作业', name: '9' },
      ],
      // 课程数据
      selectOptions: [],
      // 列表数据
      listDatas: [],
      // 我的作业课程选中id
      courseId: '',
    };
  },
  created() {
    const { query = {} } = this.$route || {};
    this.activeName = query.type || '1';
  },
  mounted() {
    console.log("here", this.activeName)
    const { query = {} } = this.$route || {};
    this.activeName = query.type || '1';
    this.pageNo = 1;
    this.radioValue = '0';
    this.courseId = '';
    if (this.activeName === '4') {
      this.getCourseDatas();
    } else {
      this.getDatas();
    }
  },
  methods: {
    // 获取课程数据
    getDatas() {
      const that = this;
      this.$request
        .get(`/studyCourse/queryMyCourse?lang=${this.radioValue}&pageNo=${this.pageNo}&type=${this.activeName}`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          let courseData = data;
          let rs = courseData.records;
          let idx = 1
          for (let k in rs) {
            rs[k]['numId'] = idx;
            idx++;
            rs[k].pic = CDNURL + rs[k].pic;
          }
          that.listDatas = courseData; // { ...data };
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取作业数据
    getWorkDatas() {
      const that = this;
      this.$request
        .get(`/studyCourse/queryMyCourse?pageNo=${this.pageNo}&type=${this.activeName}&courseId=${this.courseId}`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          that.listDatas = { ...data };
          const newDatas = data.records.map((item, index) => {
            item.numId = index + 1;
            return item;
          });
          that.listDatas = { ...data, records: newDatas };
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取课程下拉数据
    getCourseDatas() {
      const that = this;
      this.$request
        .get(`/studyCourse/myCourse`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          that.selectOptions = { ...data };
          that.getWorkDatas();
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getDatas();
    },
    handleTabClick(tab) {
      this.activeName = tab.props.name;
      this.pageNo = 1;
      this.radioValue = '0';
      this.courseId = '';
      console.log("tab.props.name", tab.props.name);
      if (tab.props.name === '9') {
        this.getCourseDatas();
      } else {
        this.getDatas();
      }
    },
    radioChangeHandle(v) {
      this.pageNo = 1;
      this.getDatas();
    },
    selectChangeHandle(v) {
      this.pageNo = 1;
      this.getWorkDatas();
    },
    // 跳到详情
    toWorkPage(row) {
      if (row.status === 2) {
        this.$router.push(`/course/homework/done/${row.id}`);
      } else {
        this.$router.push(`/course/homework/${row.examPaperId}`);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.table-list {
  .el-table {
    margin-bottom: 0;
  }
}
</style>
<style lang="scss" module>
@import 'index.scss';
</style>
