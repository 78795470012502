
import { Options, Vue } from 'vue-class-component';
import { PROGRAMURL } from '@/utils/constants';

@Options({
  props: {
    onStartProgram: Function,
    onChange: Function,
    quesData: Object,
    showAnswer: Boolean,
  },
})
export default class Program extends Vue {
  PROGRAMURL = PROGRAMURL;
  $store: any;
  token = localStorage.getItem('token'); // 是否登录
  onStartProgram!: (data: ObjectT) => {};
  quesData: ObjectT = {};

  // 点击开始编程
  handleStartProgram() {
    if (this.onStartProgram) {
      this.onStartProgram(this.quesData);
    }
  }
  // 获取编程题类型名称
  getLangName(lang: number) {
    let langName = '';
    switch (lang) {
      case 2:
        langName = '图形化';
        break;
      case 3:
        langName = 'python';
        break;
      default:
        break;
    }
    return langName;
  }
  // 查看代码
  handleViewCode(lang: number, ans: string) {
    const langName = this.getLangName(lang);
    const url = `${PROGRAMURL}${langName}/?token=${this.token}${ans ? `&codeUrl=${ans}` : ''}`;
    window.open(url, '_blank');
  }
}
