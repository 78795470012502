
import { Options, Vue } from 'vue-class-component';
import AnswerTopTitle from '@/components/panels/AnswerTopTitle.vue';
import AnswerHead from '@/components/panels/AnswerHead.vue';
import SingleChoice from '@/components/questions/SingleChoice.vue';
import MultipleChoice from '@/components/questions/MultipleChoice.vue';
import Completion from '@/components/questions/Completion.vue';
import QA from '@/components/questions/QA.vue';
import Program from '@/components/questions/Program.vue';
import ProgramPanel from '@/components/panels/Program.vue';
import * as examService from '@/services/exam';
import * as homeworkService from '@/services/homework';
import { ElMessage } from 'element-plus';
import { formatTime } from '@/utils/utils';
import defaultImg from '@/assets/img/user.png';

@Options({
  props: {
    isExam: Boolean,
    isExamAnswer: Boolean,
    isHomeworkAnswer: Boolean,
  },
  components: {
    AnswerTopTitle,
    AnswerHead,
    SingleChoice,
    MultipleChoice,
    Completion,
    QA,
    Program,
    ProgramPanel,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isSubmit) {
      next();
      return;
    }
    this.$confirm('您还没有提交试卷，确定要离开吗？', '提示')
      .then(() => {
        next();
      })
      .catch(() => {
        next(false);
      });
  },
  computed: {
    sortStart() {
      return this.size * (this.page - 1) + 1;
    },
    sortEnd() {
      return this.size * this.page;
    },
  },
  watch: {
    page() {
      document.body.scrollIntoView(true);
    },
  },
})
export default class QuestionPaper extends Vue {
  $router: any;
  $store: any;
  $confirm: any;
  isExam!: boolean; // 是否是考试模式
  isExamAnswer!: boolean; // 成绩查询
  isHomeworkAnswer!: boolean; // 作业查询
  isAnswer!: boolean; // 是否是答案模式
  questionDetail: ObjectT = {}; // 题卷详情
  questionContent: ObjectT = {}; // 题目内容
  pageId: any; // 页面id(试卷或练习卷id)
  page = 1; // 当前页数
  size = 5; // 每页显示的题目数
  maxPage = 1; // 最大页数
  singleChoiceList: ObjectT[] = []; // 单选题列表
  multipleChoiceList: ObjectT[] = []; // 多选题列表
  completionList: ObjectT[] = []; // 填空题列表
  qaList: ObjectT[] = []; // 问答题列表
  programList: ObjectT[] = []; // 编程题列表
  questionNum = 0; // 大题编号
  questionNumMap = ['一', '二', '三', '四', '五']; // 大题对应的中文序号
  questionTypeNameMap = ['单选题', '多选题', '填空题', '问答题', '编程题'];
  timeRemain = '-'; // 剩余时间
  countDownTimer: any = null; // 倒计时定时器
  savePaperTimer: any = null; // 保存试卷答题内容定时器
  isLogin = !!localStorage.getItem('token'); // 是否登录
  userInfo = {}; // 用户信息
  sideSheetFixed = false; // 左侧答题卡是否固定
  sideSheetPos = {
    x: 0,
    y: 0,
  }; // 左侧答题卡固定的时候的定位位置值
  sideSheetEl: any; // 左侧答题卡ref对象
  sideSheetInitTop = 0; // 左侧答题卡默认top值
  showProgramPanel = false; // 是否显编程题面板
  currProgramData = {}; // 当前编程题数据
  isSubmit = false; // 是否提交了卷子
  showAnswer = false; // 是否显示答案模式
  answerNum: any = 0; // 试卷类型（正式考卷/模拟）
  defaultImg = defaultImg;
  restrictNum: number | null = null; // 考试时跳出页面的限制(-1为不限制)

  created() {
    this.pageId = this.$route.params.id;
    this.answerNum = this.$route.query.answerNum ? this.$route.query.answerNum : 0;
    this.isAnswer = this.isExamAnswer || this.isHomeworkAnswer;
    const restrictNumObjStr = localStorage.getItem('restrictNum');
    if (restrictNumObjStr) {
      const restrictNumObj = JSON.parse(restrictNumObjStr);
      if (restrictNumObj[this.pageId]) {
        this.restrictNum = restrictNumObj[this.pageId];
      }
    }
  }
  async mounted() {
    if (!this.isLogin) {
      // 未登录
      ElMessage.error('请先登录');
      this.showLoginPop();
      return;
    }
    this.getUserInfo();
    await this.getQuestionContent();

    if (this.isExam && this.restrictNum !== null && this.questionContent.question) {
      // 监听浏览器tab切换
      this.watchPageVisibilityChange();
    }

    if (this.isExam) {
      // 考试模式检查是否在考试时间内
      const isValidExamTime = this.checkIsValidExamTime();
      if (!isValidExamTime) {
        this.isSubmit = true;
        this.handleGoBack();
        return;
      }
    }
    if (this.isExam && this.questionContent.examTime) {
      // 考试模式倒计时
      const now = new Date().getTime();
      const startTime = new Date(this.questionContent.startTime).getTime();
      const endTime = startTime + this.questionContent.examTime * 60 * 1000;
      const remainTime = endTime - now;
      this.countDown(Math.ceil(remainTime / 1000));
    }

    if (!this.questionContent.question || this.isAnswer) {
      // 说明页面报错了或者成绩查询模式允许返回
      this.isSubmit = true; // 允许退出当前页面
    }
    if (this.questionContent.question && this.isExam) {
      this.enableSavePaper();
    }

    this.sideSheetEl = this.$refs.sideSheet;
    if (!this.sideSheetEl) {
      return;
    }
    this.sideSheetInitTop = this.sideSheetEl.getBoundingClientRect().top;
    // 监听页面滚动
    window.addEventListener('scroll', () => {
      // this.handleSideSheetFixed();
    });

    window.addEventListener('resize', () => {
      this.calcSideSheetPos();
    });
  }
  beforeUnmount() {
    clearInterval(this.countDownTimer);
    clearInterval(this.savePaperTimer);
    this.destroyPageVisibilityChange();
  }

  // 获取tab切换事件名
  getTabVisibilityChangeName() {
    let visibilityChange = '';
    const newDocument: any = document;
    if (typeof newDocument.hidden !== 'undefined') {
      visibilityChange = 'visibilitychange';
    } else if (typeof newDocument.mozHidden !== 'undefined') {
      visibilityChange = 'mozvisibilitychange';
    } else if (typeof newDocument.msHidden !== 'undefined') {
      visibilityChange = 'msvisibilitychange';
    } else if (typeof newDocument.webkitHidden !== 'undefined') {
      visibilityChange = 'webkitvisibilitychange';
    }
    return visibilityChange;
  }

  // 移除页面隐藏监听
  destroyPageVisibilityChange() {
    const visibilityChange = this.getTabVisibilityChangeName();
    document.removeEventListener(visibilityChange, this.handleBrowerTabChange, false);
  }

  // 监听当前页面的显示隐藏
  watchPageVisibilityChange() {
    const visibilityChange = this.getTabVisibilityChangeName();
    // 添加监听器
    document.addEventListener(visibilityChange, this.handleBrowerTabChange, false);
  }

  // 处理tab切换的逻辑处理
  handleBrowerTabChange() {
    let hidden = '';
    const newDocument: any = document;
    if (typeof newDocument.hidden !== 'undefined') {
      hidden = 'hidden';
    } else if (typeof newDocument.mozHidden !== 'undefined') {
      hidden = 'mozHidden';
    } else if (typeof newDocument.msHidden !== 'undefined') {
      hidden = 'msHidden';
    } else if (typeof newDocument.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
    }
    if (!newDocument[hidden] && this.restrictNum !== null) {
      const restrictNumObjStr = localStorage.getItem('restrictNum');
      if (restrictNumObjStr) {
        const restrictNumObj = JSON.parse(restrictNumObjStr);
        if (this.restrictNum <= 1) {
          // 自动提交
          this.saveOrCommitPaper(2);
        }
        restrictNumObj[this.pageId] -= 1;
        this.restrictNum -= 1;
        localStorage.setItem('restrictNum', JSON.stringify(restrictNumObj));
        ElMessage.info(
          `您已切出${this.questionContent.restrictNum - restrictNumObj[this.pageId]}次，剩余${
            restrictNumObj[this.pageId]
          }次。${this.restrictNum >= 1 ? '超过次数将' : '已'}自动交卷！`,
        );
      }
    }
  }

  // 检查是否在考试时间内
  checkIsValidExamTime() {
    // 检查浏览器
    // const isChrome = window.navigator.userAgent.indexOf("Chrome") !== -1;
    // console.log('isChrome', isChrome, window.navigator.userAgent)
    // if (!isChrome) {
    //   ElMessage.error('为保证考试正常进行，请使用谷歌浏览器进行考试！');
    //   return false;
    // }
    const startTime = new Date(this.questionContent.startTime).getTime();
    const now = new Date().getTime();
    if (now < startTime) {
      ElMessage.error('考试未开始');
      return false;
    }
    if (now > startTime + this.questionContent.examTime * 60 * 1000) {
      ElMessage.error('考试已结束');
      return false;
    }
    return true;
  }

  // 处理点击返回
  handleGoBack() {
    if (this.isExam) {
      // 考试模式
      this.$router.push('/exam/' + this.pageId);
    } else if (this.isExamAnswer) {
      // 成绩查询模式
      this.$router.push('/user/exam?type=3');
    } else if (this.isHomeworkAnswer) {
      // 作业查询模式
      this.$router.push('/user/course?type=3');
    } else {
      // 作业模式
      this.$router.push('/user/course?type=3');
    }
  }

  // 启用定时保存答题内容(1分钟保存一次)
  enableSavePaper() {
    this.savePaperTimer = setInterval(() => {
      this.saveOrCommitPaper(1);
    }, 60000);
  }

  // 提交试卷操作
  beforeCommitPaper() {
    let pass = 1;
    for (let i = 0; i < this.questionContent.newQuestion.length; i++) {
      const item = this.questionContent.newQuestion[i];
      if (!item.status) {
        // 有题目未作答
        pass = 0;
        break;
      }
    }
    if (!pass) {
      // 有题目未作答
      this.$confirm('您还有题目未作答，确定要提交吗？', '提示')
        .then(() => {
          this.saveOrCommitPaper(2);
        })
        .catch(() => {
          // 取消
        });
    } else {
      this.saveOrCommitPaper(2);
    }
  }

  // 保存或提交答题内容
  async saveOrCommitPaper(type: number) {
    console.log("saveOrCommitPaper", this.isExam);
    const answer: examService.SubmitAnswerDataType[] = [];
    console.log(this.questionContent.newQuestion)
    this.questionContent.newQuestion.map((item: ObjectT) => {
      const data: any = {
        questionId: item.id,
        answer: item.answer,
        questionType: item.questionType,
        studentId: item.studentId,
      };
      
      if (this.isExam) {
        // 考试模式
        data.examId = item.examId;
        data.answerNum = item.answerNum;
      } else {
        // 作业模式
        data.examPaperId = this.pageId;
      }
      console.log(data)
      answer.push(data);
      return item;
    });
    
    const resp = this.isExam ? await examService.submit(type, answer) : await homeworkService.submit(type, answer);
    const data = resp.data;
    console.log(data)
    if (!data.code) {
      this.isSubmit = true;
      if (type === 1 && !this.isExam) {
        ElMessage.success('保存成功');
      }
      if (type === 2) {
        if (this.isExam) {
          this.$router.push('/exam');
        } else {
          this.$router.push('/user/course?type=3');
        }
        ElMessage.success('提交成功');
      }
    }
  }

  async saveOrCommitPaper2(type: number) {
    console.log("saveOrCommitPaper");
    const answer: examService.SubmitAnswerDataType[] = [];
    console.log(this.questionContent.newQuestion)
    this.questionContent.newQuestion.map((item: ObjectT) => {
      const data: any = {
        questionId: item.id,
        answer: item.answer,
        questionType: item.questionType,
        studentId: item.studentId,
      };
      
      if (this.isExam) {
        // 考试模式
        data.examId = item.examId;
        data.answerNum = item.answerNum;
      } else {
        // 作业模式
        data.examPaperId = item.examPaperId;
      }
      answer.push(data);
      return item;
    });
    
    const resp = this.isExam ? await examService.submit(type, answer) : await homeworkService.submit(type, answer);
    const data = resp.data;
    console.log(data)
    if (!data.code) {
      this.isSubmit = true;
      if (type === 1) {
        ElMessage.success('保存成功');
      }
      if (type === 2) {
        if (this.isExam) {
          this.$router.push('/exam');
        } else {
          this.$router.push('/user/course?type=3');
        }
        ElMessage.success('提交成功');
      }
    }
  }

  // 处理侧边导航固定
  handleSideSheetFixed() {
    if (!this.sideSheetInitTop && this.sideSheetEl) {
      this.sideSheetInitTop = this.sideSheetEl.getBoundingClientRect().top;
    }
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    this.calcSideSheetPos();
    if (scrollTop > this.sideSheetInitTop) {
      this.sideSheetFixed = true;
    } else {
      this.sideSheetFixed = false;
    }
  }

  // 计算侧边答题卡的定位值
  calcSideSheetPos() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    const winW = document.body.clientWidth;
    const winH = document.body.clientHeight;
    const footer: any = document.querySelector('.footer');
    const footerTop = footer.offsetTop;
    this.sideSheetPos.x = (winW - 1200) / 2;
    if (this.sideSheetPos.x < 0) {
      this.sideSheetPos.x = 0;
    }
    if (winH + scrollTop > footerTop - 32) {
      this.sideSheetPos.y = -(winH + scrollTop - footerTop - 32);
    } else {
      this.sideSheetPos.y = 16;
    }
  }
  // 显示登录弹窗
  showLoginPop() {
    this.$store.commit('setLoginVisible', true);
  }

  // 获取用户信息
  getUserInfo() {
    let userInfo = localStorage.getItem('userInfo');
    if (!userInfo) {
      userInfo = '';
    }
    this.userInfo = JSON.parse(userInfo);
  }

  // 调整获取到的题卷数据
  adjustQuestionContent(data: ObjectT) {
    const questionTypeNoMap = ['一', '二', '三', '四', '五']; // 题型序号
    let currNoIndex = 0; // 题型序号索引值
    let totalQuestionIndex = 1; // 当前题目的题号
    data.newQuestion = [];
    const questionObj: ObjectT = {};
    const answerObj: ObjectT = {};
    data.question.map((item: ObjectT) => {
      questionObj[item.id] = item;
      return item;
    });
    if (data.answer) {
      data.answer.map((item: ObjectT) => {
        answerObj[item.questionId] = item;
        return item;
      });
    }
    const questionList = [];
    if (data.singleChoiceList && data.singleChoiceList.length) {
      questionList.push(data.singleChoiceList);
      data.singleChoiceIndex = questionTypeNoMap[currNoIndex];
      currNoIndex += 1;
    }
    if (data.multipleChoiceList && data.multipleChoiceList.length) {
      questionList.push(data.multipleChoiceList);
      data.multipleChoiceIndex = questionTypeNoMap[currNoIndex];
      currNoIndex += 1;
    }
    if (data.gapFillingList && data.gapFillingList.length) {
      questionList.push(data.gapFillingList);
      data.gapFillingIndex = questionTypeNoMap[currNoIndex];
      currNoIndex += 1;
    }
    if (data.questionsList && data.questionsList.length) {
      questionList.push(data.questionsList);
      data.questionsIndex = questionTypeNoMap[currNoIndex];
      currNoIndex += 1;
    }
    if (data.programmeList && data.programmeList.length) {
      questionList.push(data.programmeList);
      data.programmeIndex = questionTypeNoMap[currNoIndex];
      currNoIndex += 1;
    }
    
    questionList.map((item: ObjectT[]) => {
      item = item.map((item: ObjectT) => {
        const newItem = {
          totalSort: totalQuestionIndex,
          ...item,
          ...questionObj[item.id],
        };
        
        if (answerObj[item.id]) {
          newItem.answer = answerObj[item.id].answer;
          newItem.studentId = answerObj[item.id].studentId;
          
          if (this.isExam) {
            console.log("11111", answerObj[item.id]);
            newItem.examId = answerObj[item.id].examId;
            newItem.answerNum = answerObj[item.id].answerNum;
          } else {
            console.log("22222", answerObj[item.id]);
            newItem.examPaperId = answerObj[item.id].examPaperId;
          }
        }
        totalQuestionIndex += 1;
        return newItem;
      });
      data.newQuestion = data.newQuestion.concat(item); // 重新排序后的所有题目数据
    });
    console.log("newQuestion", data.newQuestion);
    return data;
  }

  // 获取题卷内容
  async getQuestionContent() {
    let resp: any = {};
    if (this.isExam) {
      // 考试
      resp = await examService.startExam(this.pageId);
    } else if (this.isExamAnswer) {
      // 考试成绩查询
      resp = await examService.rightAnswers(this.pageId, this.answerNum);
    } else if (this.isHomeworkAnswer) {
      // 作业查询
      resp = await homeworkService.rightAnswers(this.pageId);
    } else {
      // 作业
      console.log("================")
      resp = await homeworkService.startDoHomework(this.pageId);
    }
    const { data } = resp;
    if (data.code) {
      return;
    }
    const questionContent = data.data;
    this.questionContent = this.adjustQuestionContent(questionContent);
    this.maxPage = Math.ceil(this.questionContent.newQuestion.length / this.size);

    // 考试模式下如果有跳出页面次数限制，则保存次数
    if (this.isExam && this.questionContent.restrictNum && this.restrictNum === null) {
      this.restrictNum = this.questionContent.restrictNum;
      const restrictNumObjStr = localStorage.getItem('restrictNum');
      let restrictNumObj: ObjectT = {};
      if (restrictNumObjStr) {
        restrictNumObj = JSON.parse(restrictNumObjStr);
        restrictNumObj[this.pageId] = this.questionContent.restrictNum;
      } else {
        restrictNumObj[this.pageId] = this.questionContent.restrictNum;
      }
      localStorage.setItem('restrictNum', JSON.stringify(restrictNumObj));
    }
  }

  // 倒计时
  countDown(initTime: number) {
    let time = initTime;
    this.timeRemain = formatTime(time);
    clearInterval(this.countDownTimer);
    this.countDownTimer = setInterval(() => {
      time = time - 1;
      this.timeRemain = formatTime(time);
      if (!time) {
        ElMessage.success('时间已到，试卷已自动提交');
        this.saveOrCommitPaper(2);
        clearInterval(this.countDownTimer);
      }
    }, 1000);
  }

  // 点击上一页
  go2PrevPage() {
    if (this.page > 1) {
      this.page -= 1;
    }
  }
  // 点击下一页
  go2NextPage() {
    if (this.page < this.maxPage) {
      this.page += 1;
    }
  }
  // 点击题号跳到指定题目
  go2Question(sort: number) {
    const page = Math.ceil(sort / this.size);
    if (page !== this.page) {
      this.page = page;
    }
    const el: any = this.$refs['q' + sort];
    if (el) {
      const timer = setTimeout(() => {
        el.scrollIntoView(true);
        clearTimeout(timer);
      }, 50);
    }
  }

  // 处理题目答题变更
  handleAnswerChange(type: number, totalSort: number, value: any) {
    switch (type) {
      case 1:
        this.questionContent.newQuestion[totalSort - 1].status = true;
        this.questionContent.newQuestion[totalSort - 1].answer = value;
        break;
      case 2:
        if (value.length) {
          this.questionContent.newQuestion[totalSort - 1].status = true;
        } else {
          this.questionContent.newQuestion[totalSort - 1].status = false;
        }
        this.questionContent.newQuestion[totalSort - 1].answer = value.join('|');
        break;
      case 3: {
        const newValue: string[] = [];
        let answeredNum = 0;
        value.map((item: string) => {
          const newItem = item.trim();
          newValue.push(newItem);
          if (newItem) {
            answeredNum += 1;
          }
        });
        if (answeredNum === newValue.length) {
          this.questionContent.newQuestion[totalSort - 1].status = true;
        } else {
          this.questionContent.newQuestion[totalSort - 1].status = false;
        }
        this.questionContent.newQuestion[totalSort - 1].answer = newValue.join('|');
        break;
      }
      case 4: {
        const newValue = value.trim();
        if (newValue) {
          this.questionContent.newQuestion[totalSort - 1].status = true;
        } else {
          this.questionContent.newQuestion[totalSort - 1].status = false;
        }
        this.questionContent.newQuestion[totalSort - 1].answer = newValue;
        break;
      }
      case 5:
        this.questionContent.newQuestion[totalSort - 1].answer = value;
        this.questionContent.newQuestion[totalSort - 1].status = true;
        break;
      default:
        break;
    }
  }

  // 处理点击开始编程
  handleStartProgram(data: ObjectT) {
    this.currProgramData = data;
    this.showProgramPanel = true;
    document.body.style.overflow = 'hidden';
  }

  // 处理编程面板关闭
  handleCloseProgramPanel() {
    this.showProgramPanel = false;
    document.body.style.overflow = 'auto';
  }
}
