// 时间位数格式化
export function singleTime2Double(time: number) {
  return time < 10 ? '0' + time : time;
}

// 时间格式化
export function formatTime(time: number) {
  let s: number | string = 0;
  let m: number | string = 0;
  let h: number | string = 0;
  if (time < 60) {
    s = time;
  } else if (time / 60 < 60) {
    s = time % 60;
    m = (time - s) / 60;
  } else {
    s = time % 60;
    m = ((time - s) / 60) % 60;
    h = (time - s - m * 60) / 60 / 60;
  }
  s = singleTime2Double(s);
  m = singleTime2Double(m);
  if (h) {
    h = singleTime2Double(h);
    return [h, m, s].join(':');
  }
  return [m, s].join(':');
}
