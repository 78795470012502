<template>
  <div :class="$style['tabs']">
    <div class="nav wrapper">
      <el-tabs v-model="activeName">
        <el-tabs v-model="activeName">
          <el-tab-pane
            :label="item.label"
            :name="item.name"
            v-for="item in navData"
            v-bind:key="item.name"
          ></el-tab-pane>
        </el-tabs>
      </el-tabs>
    </div>
  </div>
  <div class="bg">
    <div class="wrapper">
      <el-card :class="$style['user-box']" v-if="activeName === '1'">
        <el-form :model="userForm" :rules="rules" ref="userForm" label-width="100px" class="demo-ruleForm">
          <h6 :class="$style['user-header']">
            <span>基本信息：</span>
            <el-button type="primary" size="small" :class="$style['sm-button']" @click="submitForm('userForm')"
              >保存</el-button
            >
          </h6>
          <el-form-item label="头像：" prop="pic">
            <div :class="$style['user-form-item']">
              <img
                :class="$style['user-upload-img']"
                :src="userForm.avatar ? userForm.avatar : defaultImg"
                :onerror="`onerror=null;src='${defaultImg}'`"
              />
              <el-upload
                list-type="picture-card"
                class="user-upload"
                :class="$style['user-upload-active']"
                action="http://175.24.98.133:9090/api/studyStudent/uploadHeadPic"
                :headers="{ Authorization: getAuth }"
                :on-success="handleUploadSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <template #default>
                  修改
                </template>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="昵称：" prop="nickname">
            <el-input v-model="userForm.nickname" placeholder="请输入昵称" :class="$style['user-input']"></el-input>
          </el-form-item>
          <el-form-item label="性别：" prop="sex">
            <el-radio-group v-model="userForm.sex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card :class="$style['user-box']" v-else>
        <h6 :class="$style['user-header']">
          <span>账号信息：</span>
        </h6>
        <div :class="$style['user-password']">
          <p :class="$style['password-label']">
            <span>账号：</span> <span :class="$style['password-txt']">{{userForm.username}}</span>
          </p>
          <p :class="$style['password-label']">
            <span>姓名：</span> <span :class="$style['password-txt']">{{userForm.realname}}</span>
          </p>
          <p :class="$style['password-label']">
            <span>密码：</span> <span :class="$style['password-txt']">已设置密码</span>
            <el-button type="primary" size="small" :class="$style['edit-passwprd']" @click="showEditPop"
              >修改密码</el-button
            >
          </p>
        </div>
      </el-card>
    </div>
    <el-dialog
      title="修改密码"
      v-model="editVisible"
      width="500px"
      :before-close="closeFormHandle"
      :show-close="false"
      center
      custom-class="login-pop"
    >
      <p :class="$style['edit-msg']">为了保证你的账号安全，密码至少6个字符，<br />建议包含字母和数字</p>
      <el-form :model="editRuleForm" :rules="editRules" ref="editRuleForm" status-icon>
        <el-form-item prop="oldPassword">
          <el-input
            v-model="editRuleForm.oldPassword"
            autocomplete="off"
            size="medium"
            type="password"
            placeholder="请输入旧密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="newPassword">
          <el-input
            v-model="editRuleForm.newPassword"
            autocomplete="off"
            size="medium"
            placeholder="请输入新密码"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword">
          <el-input
            v-model="editRuleForm.confirmPassword"
            autocomplete="off"
            size="medium"
            type="password"
            placeholder="请再次输入新密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeFormHandle">取 消</el-button>
          <el-button type="primary" @click="submitEditForm('editRuleForm')">提 交</el-button>
        </span>
        <p :class="$style['edit-msg']">修改密码后，需要重新登录账号</p>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import defaultImg from '@/assets/img/user.png';
import { ElMessage } from 'element-plus';
import { CDNURL } from '@/utils/constants';
export default {
  data() {
    // 新密码验证
    const validateNewPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else {
        if (this.editRuleForm.confirmPassword !== '') {
          this.$refs.editRuleForm.validateField('checkPass');
        }
        callback();
      }
    };
    // 确认密码验证
    const validateConfirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'));
      } else if (value !== this.editRuleForm.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      // tab选中
      activeName: '1',
      // tab数据
      navData: [
        { label: '基本信息', name: '1' },
        { label: '账号信息', name: '2' },
      ],
      // 用户数据
      userForm: {
        avatar: '',
        nickname: '',
        sex: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入昵称', trigger: 'blur' },
          { min: 3, max: 30, message: '长度在 3 到 30 个字符', trigger: 'blur' },
        ],
      },
      dialogImageUrl: '',
      dialogVisible: false,
      // 修改密码弹窗
      editVisible: false,
      // 密码字段
      editRuleForm: {
        newPassword: '',
        confirmPassword: '',
        oldPassword: '',
      },
      // 密码规则
      editRules: {
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            min: 6,
            trigger: 'blur',
            message: '密码至少6个字符',
          },
          { validator: validateNewPassword, trigger: 'blur' },
        ],
        confirmPassword: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          { validator: validateConfirmPassword, trigger: 'blur' },
        ],
        oldPassword: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
      },

      // 默认头像
      defaultImg: defaultImg,
      cdnUrl: CDNURL,
    };
  },
  mounted() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
    this.userForm = userInfo;
  },
  computed: {
    getAuth() {
      return localStorage.getItem('token') || '';
    },
  },
  methods: {
    // 保存基本信息
    submitForm(formName) {
      const that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$request
            .put(`/studyStudent/edit`, { ...this.userForm })
            .then(function(res) {
              console.log('res', res);
              const { data = {} } = res || {};
              if (data.code === 0) {
                ElMessage.success({
                  message: '基本信息修改成功',
                  type: 'success',
                });
                that.getUserData();
              }
              // that.$router.go(0);
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    // 获取个人信息
    getUserData() {
      const that = this;
      this.$request
        .get(`/studyStudent/studentInfo`)
        .then(function(res) {
          const { data = {} } = res.data || {};
          if (res.data.code === 0) {
            const userInfoData = {
              avatar: data.avatar, // 图片
              nickname: data.nickname, // 名字
              sex: data.sex, // 性别
              id: that.userForm.id, // id
            };
            localStorage.setItem('userInfo', JSON.stringify(userInfoData));
            that.$router.go(0);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleRemove() {
      this.dialogVisible = true;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 修改密码
    submitEditForm(formName) {
      const that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$request
            .put(`/studyStudent/editPassword`, { ...this.editRuleForm })
            .then(function() {
              localStorage.clear();
              that.$router.go(0);
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    // 打开弹窗
    showEditPop() {
      this.editVisible = true;
    },
    // 关闭弹窗
    closeFormHandle() {
      this.editVisible = false;
    },
    handleUploadSuccess(e) {
      if (e.code === 0) {
        this.userForm.avatar = this.cdnUrl + e.data;
      }
      if (e.code === 10002) {
        localStorage.clear();
        window.location.reload();
      }
    },
    // 上传格式验证
    beforeAvatarUpload(file) {
      const types = ['image/jpeg', 'image/jpg', 'image/png'];
      const isImage = types.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 0.5;
      if (!isImage) {
        this.$message.error('上传头像图片只能是 JPG、JPEG、PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 500KB!');
      }
      return isImage && isLt2M;
    },
  },
};
</script>
<style lang="scss">
.user-upload {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
  display: none;
  .el-upload--picture-card {
    width: 120px;
    height: 120px;
    line-height: 120px;
    border-radius: 120px;
    background-color: rgba($color: #000000, $alpha: 0.5);
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  .el-upload-list {
    display: none;
  }
}
</style>
<style lang="scss" module>
@import 'index.scss';
</style>
