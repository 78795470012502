<template>
  <el-card :body-style="{ padding: '0px' }" class="card" @click="toCourseDetail(dataList.id)" :data-id="dataList.id">
    <img :src="dataList.pic ? dataList.pic : empty" class="image" :onerror="`onerror=null;src='${empty}'`" />
    <div style="padding: 14px;">
      <span>{{ dataList.courseName }}</span>
      <el-rate
        v-model="dataList.difficulty"
        allow-half
        disabled
        disabled-void-color="#161c2d"
        class="star-items"
        :colors="['#ffe074', '#ffe074', '#ffe074']"
      />
      <div class="bottom">
        <span><svg-icon icon-class="iconperson" class="list-icon"></svg-icon>{{ dataList.studyNum }}人</span>
        <span><svg-icon icon-class="icontime" class="list-icon"></svg-icon>{{ dataList.lessonNum }}课次</span>
      </div>
    </div>
  </el-card>
</template>
<script>
import empty from '@/assets/img/empty.png';
export default {
  props: ['data'],
  data() {
    return {
      // 数据
      dataList: this.data,
      // 空图片
      empty: empty,
    };
  },
  methods: {
    toCourseDetail(id) {
      this.$router.push(`/course/${id}`);
    },
  },
};
</script>
