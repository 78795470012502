<template>
  <div :class="$style['bg-color']" style="padding-top:0;">
    <div :class="$style['top-bg']">
      <div class="wrapper">
        <el-breadcrumb separator="/" class="wrapper breadcrumb" :class="$style['breadcrumb']">
          <el-breadcrumb-item :to="{ path: '/course' }">课程</el-breadcrumb-item>
          <el-breadcrumb-item>{{ detailData.courseName }}</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="box-card course-top-con">
          <div :class="$style['course-d-con']" class="clearfix">
            <div :class="$style['d-img']">
              <img
                :src="detailData.pic ? cdnURL + detailData.pic : empty"
                class="image"
                :onerror="`onerror=null;src='${empty}'`"
              />
            </div>
            <div :class="$style['d-info']">
              <h3>{{ detailData.courseName }}</h3>
              <ul>
                <li>课程形式：{{ detailData.kind === 1 ? '直播课' : ( detailData.kind === 2 ? '录播课' : ( detailData.kind === 3 ? '双师课' : '启迪义教' ) ) }}</li>
                <li>编程语言：{{ detailData.type === 2 ? '图形化' : ( detailData.type == 3 ? 'Python' : ( detailData.type == 4 ? '机器人' : '信息科技' )  ) }}</li>
                <li :class="$style['diff']" class="clearfix">
                  <span>课程难度：</span>
                  <el-rate
                    v-model="detailData.difficulty"
                    allow-half
                    disabled
                    disabled-void-color="#161c2d"
                    class="star-items"
                    :colors="['#ffe074', '#ffe074', '#ffe074']"
                  >
                  </el-rate>
                </li>
                <li :class="$style['num']">
                  <span
                    ><svg-icon icon-class="iconperson" class="list-icon"></svg-icon>{{ detailData.studyNum }}人</span
                  >
                  <span
                    ><svg-icon icon-class="icontime" class="list-icon"></svg-icon>{{ detailData.lessonNum }}课次</span
                  >
                </li>
              </ul>
              <div v-if="detailData.kind === 1 || detailData.kind === 3" :class="$style['time']">
                <span v-if="detailData.description && detailData.description !== null"
                  >近期直播时间：{{ detailData.description }}</span
                >
                <span v-else>暂未开播，请联系老师咨询开播时间</span>
                <a class="entry-room" @click="enter">进入直播间</a>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div :class="$style['tabs']">
      <div class="nav wrapper">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="课程介绍" name="1"></el-tab-pane>
          <el-tab-pane label="章节内容" name="2"></el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <div :class="$style['detail-con']">
      <div class="wrapper">
        <el-card class="box-card detail-con-box" v-if="activeName === '1'">
          <div v-html="detailData.courseDesc"></div>
        </el-card>
        <el-card class="box-card detail-con-list" v-if="activeName === '2'">
          <div :class="$style['list-con']" v-for="(item, index) in detailData.lessonList" :key="item.index">
            <h3 class="clearfix">
              <span :class="$style['num']">{{ index + 1 }}</span>
              <span :class="$style['title']">{{ item.lessonName }}</span>
              <span :class="$style['des']">{{ item.lessonDesc }}</span>
            </h3>
            <ul>
              <li v-for="resource in item.resourceList" :key="resource.index">
                <a v-if="resource.resourceKind != 3" :class="$style['button']" @click="startLearning(resource)">开始学习</a>
                <a v-else :class="$style['button']" @click="startLearning(resource)">做作业</a>
                <!-- <i
                  :class="
                    resource.resourceType === ('ppt' || 'pdf' || 'txt' || 'docx' || 'doc')
                      ? iconList.courseware
                      : resource.resourceType === ('mp4' || 'avi' || 'mpeg')
                      ? iconList.video
                      : iconList.operation
                  "
                ></i
                > -->
                <i v-if="resource.resourceKind==1" :class="iconList.video"></i>
                <i v-if="resource.resourceKind==2" :class="iconList.courseware"></i>
                <i v-if="resource.resourceKind==3" :class="iconList.operation"></i>
                {{ resource.resourceName }}
              </li>
            </ul>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import empty from '@/assets/img/empty.png';
import { CDNURL, LIVEURL } from '@/utils/constants';
import { ElMessage } from 'element-plus';
export default {
  data() {
    return {
      cdnURL: CDNURL, 
      activeName: '1',
      detailData: {},
      paramData: {
        id: '',
      },
      iconList: {
        video: 'el-icon-video-camera',
        courseware: 'el-icon-data-line',
        operation: 'el-icon-reading',
      },
      roomUrl: '',
      empty: empty,
    };
  },
  created() {
    const { params = {} } = this.$route || {};
    this.paramData.id = params.id;
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    getDatas(callback) {
      const { id } = this.paramData;
      const that = this;
      this.$request
        .get(`/studyCourse/queryCourseDetail?id=${id}`)
        .then(function(response) {
          if (response.data.code === 0) {
            const { data = {} } = response.data || {};

            that.detailData = data;
            if (callback) callback();
            // if (data.kind === 1 && data.description && data.description !== null) {
            //   const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
            //   const token = localStorage.getItem('token') || {};
            //   // console.log("userInfo", userInfo);
            //   // const { uid } = userInfo["id"];
            //   console.log("userInfo", userInfo["id"]);
            //   that.$request.get('/teachingLiveRoom/enterLiveRoom?type=1&uid=' + userInfo["id"] + '&courseId='+id).then(function(res) {
            //     if (res.data.code === 0) {
            //       const { data = {} } = res.data || {};
            //       that.roomUrl = LIVEURL + '?uid=' + userInfo["id"] + '&courseId=' + id + '&type=1&token=' + token;
            //       window.open(that.roomUrl)
            //     } else {
            //       ElMessage.error(res.msg)
            //     }
            //   });
            // }
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    enter() {
      const that = this;
      this.getDatas(function() {
        that.enter2();
      });
    },

    enter2() {
      const that = this;
      const { id } = this.paramData;
      let data = this.detailData;
      console.log("enter", data)
      if ((data.kind === 1 || data.kind === 3) && data.description && data.description !== null) {
        const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
        const token = localStorage.getItem('token') || {};
        // console.log("userInfo", userInfo);
        // const { uid } = userInfo["id"];
        console.log("userInfo", userInfo["id"]);
        that.$request.get('/teachingLiveRoom/enterLiveRoom?type=1&uid=' + userInfo["id"] + '&courseId='+id).then(function(res) {
          if (res.data.code === 0) {
            // const { data = {} } = res.data || {};
            that.roomUrl = LIVEURL + '?uid=' + userInfo["id"] + '&courseId=' + id + '&type=1&token=' + token;
            window.open(that.roomUrl)
          } else {
            ElMessage.error(res.msg)
          }
        });
      } else {
        ElMessage.error('该班级暂未开播')
      }
    },

    // tab切换
    handleClick(tab) {
      this.activeName = tab.props.name;
    },

    // 点击开始学习
    startLearning(resource) {
      const { resourceType, lessonId, courseId, resourceKind, resourceSrc } = resource;

      // 判断是否登录
      if (localStorage.getItem('token') === null) {
        this.$store.commit('setLoginVisible', true);
        return;
      }
      const that = this;
      let url = '/studyCourse/';
      // if (['ppt', 'pdf'].includes(resourceType)) {
      if (resourceKind === 2) { 
        if (['pdf'].includes(resourceType)) {
          // 课件跳转控制
        url += `startToCourseWare?courseId=${courseId}&resourceKind=${resourceKind}&lessonId=${lessonId}`;

        this.$request
          .get(url)
          .then(function(response) {
            const host = CDNURL;

            const { data = {} } = response || {};
            if (data.code === 0) {
              // window.open(host + resource.resourceSrc, '_blank');
              window.open('https://www.ideacode.com.cn/pdf/web/viewer.html?file=' + resource.resourceSrc, '_blank');
            }
          })
          .catch(function(error) {
            console.log(error);
          });
        } else {
          window.open(CDNURL + resource.resourceSrc, '_blank');
        }
      // } else if (['mp4', 'avi', 'mpeg'].includes(resourceType)) {
      } else if (resourceKind === 1) {  
        // 视频跳转控制
        url += `startLearning?courseId=${courseId}&resourceKind=${resourceKind}&lessonId=${lessonId}`;

        this.$request
          .get(url)
          .then(function(response) {
            const { data = {} } = response || {};
            if (data.code === 0) {
              // that.$router.push({
              //   path: '/course/video',
              //   query: { courseId: courseId, lessonId: lessonId, resourceSrc: resourceSrc },
              // });
              window.open('./video?courseId=' + courseId + '&lessonId=' + lessonId + '&resourceSrc=' + resourceSrc);
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      } else {
        // 作业跳转控制
        url += `/startToWork?courseId=${courseId}&resourceKind=${resourceKind}&lessonId=${lessonId}`; // 作业请求接口地址

        this.$request
          .get(url)
          .then(function(response) {
            const { data = {} } = response || {};

            if (data.code === 0) {
              that.$router.push({
                path: '/course/homework/' + resourceSrc,
              });
              // window.open('./homework/' + resourceSrc);
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
  },
};
</script>
<style lang="scss">
.breadcrumb {
  .el-breadcrumb__item:last-child .el-breadcrumb__inner,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner a,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover {
    color: #fff;
  }
}
.course-top-con {
  .el-rate__item {
    margin: 13px 0px;
  }
}

.detail-con-box,
.detail-con-list {
  .el-card__body {
    padding: 45px;
    min-height: 300px;
    .page-item {
      padding-bottom: 0px;
    }
  }
}
</style>
<style lang="scss" module>
@import './index.scss';
</style>
