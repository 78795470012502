<template>
  <div class="bg">
    <div class="wrapper">
      <el-card :class="$style['card']">
        <el-tabs v-model="activeName" class="msg-tabs" @tab-click="handleClick">
          <el-tab-pane :name="item.name" v-for="item in navData" v-bind:key="item.name">
            <template #label>
              <div :class="$style['label-dot']">
                <span> {{ item.label }}</span>

                <span :class="$style['dot']" v-if="hasReadNum(item.name)">{{ readNum(item.name) }}</span>
              </div>
            </template>
          </el-tab-pane>
        </el-tabs>
        <div :class="$style['dropdown']">
          <span :class="$style['readed']" @click="handleChangeAllReaded" v-if="hasReadNum(activeName)">
            全部标为已读
          </span>
          <el-button
            type="primary"
            size="small"
            @click="openDeleteDatas"
            v-if="listDatas.records && listDatas.records.length > 0"
          >
            清空</el-button
          >
        </div>
        <ul :class="$style['list']" v-if="listDatas.records && listDatas.records.length > 0">
          <li v-for="i in listDatas.records" v-bind:key="i.id">
            <p :class="$style['time']">
              <span :class="i.status === 0 ? $style['unread'] : ''">{{ date(i.createDate) }}</span>
              <span :class="$style['readed']" :data-id="i.id" @click="handleChangeReaded" v-if="i.status === 0"
                >标为已读</span
              >
              <span :class="$style['delete']" @click="openDeleteDatas" :data-id="i.id">
                删除<svg-icon icon-class="icondelete" class="list-delete"></svg-icon
              ></span>
            </p>
            <div v-html="i.content"></div>
          </li>
        </ul>
        <el-empty description="暂无数据" v-else></el-empty>
        <el-pagination
          @current-change="handleCurrentChange"
          v-model:currentPage="currentPage"
          background
          :page-size="10"
          layout="prev, pager, next, jumper"
          :total="listDatas.total"
          class="page-item"
          v-if="listDatas.records && listDatas.records.length > 0"
        >
        </el-pagination>
      </el-card>
    </div>
    <el-dialog title="删除确认" v-model="dialogVisible" width="300px" :before-close="handleDeleteClose">
      <span>{{ deleteTxt }}</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleDeleteClose">取 消</el-button>
          <el-button type="primary" @click="deleteDatas">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ElMessage } from 'element-plus';
import moment from 'moment';
export default {
  data() {
    return {
      // tab 选中
      activeName: '1',
      // tab 数据
      navData: [
        { label: '我的消息', name: '1' },
        { label: '系统消息', name: '2' },
      ],
      // 分页选中
      currentPage: 1,
      // 数据列表
      listDatas: {},
      // 删除弹窗
      dialogVisible: false,
      // 删除id
      deleteId: '',
      // 删除提示
      deleteTxt: '',
    };
  },
  created() {
    // 未登录跳转首页
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: `/` });
    }
  },
  mounted() {
    this.getDatas();
  },
  computed: {
    // 用户消息未读数
    unreadMsg() {
      return this.$store.state.unreadMsg;
    },
    date() {
      return (date) => {
        return moment.unix(date).format('YYYY/MM/DD HH:mm');
      };
    },
  },
  methods: {
    // 获取数据
    getDatas() {
      const that = this;
      this.$request
        .get(`/studyMsg/queryMyMsg?type=${this.activeName}&pageNo=${this.currentPage}`)
        .then(function(response) {
          that.listDatas = response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 切换tab
    handleClick(tab) {
      this.activeName = tab.props.name;
      this.currentPage = 1;
      this.getDatas();
    },
    // 分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDatas();
    },
    // 打开删除弹窗
    openDeleteDatas(e) {
      const { dataset = {} } = e.target || {};
      if (dataset.id) {
        this.deleteTxt = '请确认是否要删除消息？';
      } else {
        this.deleteTxt = '请确认是否要清空消息？';
      }
      this.deleteId = dataset.id;
      this.dialogVisible = true;
    },
    // 关闭删除弹窗
    handleDeleteClose() {
      this.dialogVisible = false;
      this.deleteId = '';
    },
    // 删除数据
    deleteDatas() {
      const that = this;
      if (this.deleteId) {
        this.$request
          .delete(`studyMsg/delete?id=${this.deleteId}`)
          .then(function() {
            ElMessage.success({
              message: '删除成功',
              type: 'success',
            });
            that.dialogVisible = false;
            that.deleteId = '';
            that.getDatas();
            that.$store.dispatch('getReadData', { that: that });
          })
          .catch(function(error) {
            console.log(error);
          });
      } else {
        this.$request
          .delete(`studyMsg/deleteBatch?type=${this.activeName}`)
          .then(function() {
            that.dialogVisible = false;
            that.getDatas();
            that.$store.dispatch('getReadData', { that: that });
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },

    // 标为已读
    handleChangeReaded(e) {
      const that = this;
      const { dataset = {} } = e.target || {};
      this.$request
        .put(`studyMsg/markAsRead`, { id: dataset.id })
        .then(function() {
          that.getDatas();
          that.$store.dispatch('getReadData', { that: that });
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    // 全部标为已读
    handleChangeAllReaded(e) {
      const that = this;
      this.$request
        .put(`studyMsg/markAsReadBatch`, { type: this.activeName })
        .then(function() {
          that.getDatas();
          that.$store.dispatch('getReadData', { that: that });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 消息数
    readNum(name) {
      const data = name === '1' ? this.unreadMsg.mineMsgNum : this.unreadMsg.mineSystemMsgNum;
      return data > 99 ? '99+' : data;
    },
    // 有无消息
    hasReadNum(name) {
      const data = name === '1' ? this.unreadMsg.mineMsgNum : this.unreadMsg.mineSystemMsgNum;
      return data > 0;
    },
  },
};
</script>
<style lang="scss">
.msg-tabs {
  .el-tabs__item {
    font-size: 18px;
    height: 40px;
    line-height: 4px;
  }
}
</style>
<style lang="scss" module>
@import 'index.scss';
</style>
