<template>
  <div class="footer">
    <!-- <div class="wrapper clearfix">
      <img src="@/assets/img/1.jpg" alt="" class="logo" />
      <ul class="list">
        <li><a href="">台江区第三中学小学</a></li>
        <li><a href="">asdasdasdasd</a></li>
        <li><a href="">asdasdasdasd</a></li>
      </ul>
      <ul class="list">
        <li><a href="">台江区第三中学小学</a></li>
        <li><a href="">asdasdasdasd</a></li>
        <li><a href="">asdasdasdasd</a></li>
      </ul>
      <ul class="list">
        <li><a href="">台江区第三中学小学</a></li>
        <li><a href="">asdasdasdasd</a></li>
        <li><a href="">asdasdasdasd</a></li>
      </ul>
      <div class="pic">
        <img src="@/assets/img/1.jpg" alt="" />
        <p>klsdfkjhsdfjkhskjksdfhj</p>
      </div>
      <div class="pic">
        <img src="@/assets/img/1.jpg" alt="" />
        <p>klsdfkjhsdfjkhskjksdfhj</p>
      </div>
    </div> -->
    <div class="info wrapper">
      <p>
        网站备案号： <a class="whitelink" href="https://beian.miit.gov.cn/" target="_blank">闽ICP备2021007227号</a> <br/>
        网站技术支持：童趣智能科技<br />
      </p>
      <!--logo替换-->
      <!-- <p>
        网站备案号： <a class="whitelink" href="https://beian.miit.gov.cn/" target="_blank">闽ICP备19007923号-1</a> <br/>
        网站技术支持：福州片刻优学科技有限公司<br />
      </p> -->
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.footer {
  position: relative;
  width: 100%;
  margin-top: 0px;
  height: 130px;
  background-color: #2f2e41;
  z-index: 9999;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 703px;
    height: 130px;
    background: url('~@/assets/img/footer1.png');
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 491px;
    height: 130px;
    background: url('~@/assets/img/footer2.png');
  }
  .logo {
    float: left;
    margin-top: 80px;
    width: 100px;
    height: 100px;
  }
  .list {
    margin-top: 70px;
    width: 260px;
    float: left;
    li {
      margin-bottom: 10px;
      text-align: center;
    }
    a {
      color: #d9dfff;
      transition: all 0.3s;
      &:hover {
        color: #fff;
      }
    }
  }
  .pic {
    float: right;
    text-align: center;
    margin-left: 20px;
    margin-top: 60px;
    img {
      display: inline-block;
      width: 100px;
      height: 100px;
      border: 6px solid #a9a9a9;
    }
    p {
      margin-top: 5px;
      color: #fff;
    }
  }
  .info {
    margin-top: 0px;
    border-top: 1px solid #51505f;
    text-align: center;
    color: #fff;
    p {
      margin-top: 35px;
      line-height:30px;
      a {
        color: #fff;
        transition: all 0.3s;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  a.whitelink a:link,
    a.whitelink a:visiteid  {
      color:#FFFFFF;
      text-decoration:none;
    }
    a.whitelink a:hover,
    a.whitelink a:active  {
      color:#FFFFFF;
      text-decoration:underline;
    }
}
</style>
