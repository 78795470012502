<template>
  <div class="bread-crumb">
    <el-breadcrumb separator="/" class="wrapper">
      <el-breadcrumb-item :to="{ path: '/exam' }">考试</el-breadcrumb-item>
      <el-breadcrumb-item>{{ type === '1' ? '正式考试' : '模拟练习' }}</el-breadcrumb-item>
      <el-breadcrumb-item>考试详情</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
  <div class="bg">
    <div class="wrapper">
      <el-card :class="$style['exam-detail']">
        <h3>{{ detailData.examName }}</h3>
        <h6>考试说明</h6>
        <ul :class="$style['list']">
          <li>
            <span :class="$style['label']">编程语言：</span>
            <span :class="$style['msg']">{{ detailData.langName }}</span>
          </li>
          <li>
            <span :class="$style['label']">考评等级：</span>
            <span :class="$style['msg']">{{ detailData.stepTxt }}</span>
          </li>
          <li>
            <span :class="$style['label']">类型：</span>
            <span :class="$style['msg']">{{ detailData.type === 1 ? '正式考试' : '模拟练习' }}</span>
          </li>
          <li v-if="detailData.type === 1">
            <span :class="$style['label']">开始时间：</span>
            <span :class="$style['msg']">{{ $moment(detailData.startTime).format('YYYY-MM-DD HH:mm') }}</span>
          </li>
          <li v-else>
            <span :class="$style['label']">有效时间：</span>
            <span :class="$style['msg']">{{ detailData.startTime }}</span>
          </li>
          <li>
            <span :class="$style['label']">考试时长：</span>
            <span :class="$style['msg']">{{ detailData.examTime }} 分钟</span>
          </li>
          <li>
            <span :class="$style['label']">考试次数：</span>
            <span :class="$style['msg']">{{ detailData.type === 1 ? '1' : '无限' }} 次</span>
          </li>
          <li>
            <span :class="$style['label']">总分：</span>
            <span :class="$style['msg']">{{ detailData.totalScore }}</span>
          </li>
          <li>
            <span :class="$style['label']">及格分数：</span>
            <span :class="$style['msg']">{{ detailData.passMark }}</span>
          </li>
        </ul>
        <p :class="$style['msg-txt']">如果考试异常中断，请退出并及时按同样步骤进入，继续考试</p>
        <el-button type="primary" :class="$style['in-btn']" @click="toExamPage">进入考试</el-button>
      </el-card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: '1',
      paramData: {
        id: '',
      },
      detailData: {},
    };
  },
  created() {
    const { query = {}, params = {} } = this.$route || {};
    this.type = query.type;
    this.paramData.id = params.id;
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    getDatas() {
      const { id } = this.paramData;
      const that = this;
      this.$request
        .get(`/studyExam/queryExamById?id=${id}`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          const stepDatas = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];
          const stepTxt = (stepDatas[data.step - 1] || '') + '级';
          that.detailData = { ...data, stepTxt: stepTxt };
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 跳到详情
    toExamPage() {
      const isLogin = !!localStorage.getItem('token');
      if (isLogin) {
        this.$router.push(`/exam/${this.detailData.id}/exampaper`);
      } else {
        this.$store.commit('setLoginVisible', true);
      }
    },
  },
};
</script>
<style lang="scss" module>
@import './index.scss';
</style>
