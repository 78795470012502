import request from '../utils/request';

export interface SubmitAnswerDataType {
  questionId: string | number;
  answer: string;
  questionType: number;
  examId?: string | number;
  answerNum?: number;
  studentId: string | number;
}

/**
 * 获取考试试卷内容
 * @param examId 考试id
 */
export function startExam(examId: number) {
  return request('/studyPaperQuestion/startExam', {
    params: {
      examId,
    },
  });
}

/**
 * 获取考试详情
 * @param id 考试id
 */
export function queryExamById(id: number) {
  return request('/studyExam/queryExamById', {
    params: {
      id,
    },
  });
}

/**
 * 考试保存/提交
 */
export function submit(type: number, answer: SubmitAnswerDataType[]) {
  return request('/studyPaperQuestion/submit', {
    method: 'post',
    data: {
      type,
      answer,
    },
  });
}

/**
 * 考试成绩查询
 */
export function rightAnswers(examId: string | number, answerNum: number) {
  return request('/studyPaperQuestion/rightAnswers', {
    params: {
      examId,
      answerNum,
    },
  });
}
