
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    onChange: Function,
    quesData: Object,
    showAnswer: Boolean,
  },
  watch: {
    quesData() {
      this.radio = '';
    },
  },
})
export default class SingleChoice extends Vue {
  quesData: ObjectT = {};
  onChange!: (type: number, totalSort: number, value: string) => {};
  showAnswer!: boolean;
  radio = '';
  created() {
    if (this.showAnswer) {
      this.radio = this.quesData.studentAnswer ? this.quesData.studentAnswer : '';
    } else {
      this.radio = this.quesData.answer ? this.quesData.answer : '';
    }
  }
  // 处理选项change
  handleOptionChange(value: string) {
    if (this.showAnswer) {
      this.radio = this.quesData.studentAnswer ? this.quesData.studentAnswer : '';
      return;
    }
    if (this.onChange) {
      this.onChange(1, this.quesData.totalSort, value);
    }
  }
}
