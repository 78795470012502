<template>
  <div :class="$style.title">
    <div :class="$style.txt">
      <span :class="$style['bg-txt']">INFORMATION</span>
      <h2 style="visibility:hidden;">资讯</h2>
      <p style="visibility:hidden;">关于我们的副标题内容关于我们的副标题内容</p>
    </div>
  </div>
  <div class="wrapper nav">
    <el-tabs v-model="paramData.type" @tab-click="handleClick">
      <el-tab-pane label="活动资讯" name="1"></el-tab-pane>
      <el-tab-pane label="活动通知" name="2"></el-tab-pane>
      <el-tab-pane label="活动报道" name="3"></el-tab-pane>
    </el-tabs>
  </div>
  <div class="bg">
    <div class=" wrapper news-list">
      <div v-if="listDatas.records && listDatas.records.length > 0">
        <el-card :class="$style['news-card']" class="clearfix" v-for="i in listDatas.records" v-bind:key="i.id">
          <div :class="$style['img-box']">
            <router-link :to="`/news/${i.id}`">
              <img
                :src="i.pic ? CDNURL + i.pic : empty"
                alt=""
                :class="$style['img']"
                :onerror="`onerror=null;src='${empty}'`"
              />
            </router-link>
          </div>

          <div :class="$style['txt-item']">
            <h6>
              <router-link :to="`/news/${i.id}`">
                <!-- <span>{{ i.digest }}</span> -->
                {{ i.title }}
              </router-link>
            </h6>
            <p :class="$style['content']">
              {{ i.digest }}
            </p>
            <p :class="$style['msg']">
              <svg-icon icon-class="icontime" :class="$style['news-icon']"></svg-icon
              >{{ $moment(i.date).format('YYYY-MM-DD') }}
            </p>
          </div>
        </el-card>
      </div>
      <el-empty description="暂无数据" v-else></el-empty>
      <el-pagination
        @current-change="handleCurrentChange"
        v-model:currentPage="currentPage"
        background
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="listDatas.total"
        class="page-item"
        v-if="listDatas.records && listDatas.records.length > 0"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import empty from '@/assets/img/empty.png';
import { CDNURL } from '@/utils/constants';
export default {
  data() {
    return {
      CDNURL: CDNURL,
      // 参数
      paramData: {
        type: '1',
      },
      // 分页
      currentPage: 1,
      // 列表数据
      listDatas: {
        records: [],
        total: 0,
      },
      // 空图片
      empty: empty,
    };
  },
  created() {
    const { query = {} } = this.$route || {};
    this.paramData.type = query.type || '1';
  },
  mounted() {
    this.getNewsData();
  },
  methods: {
    getNewsData: function() {
      const that = this;
      const { type } = this.paramData || {};
      this.$request
        .get(`/studyInfo/news?type=${type}&pageNo=${this.currentPage}`)
        .then(function(response) {
          that.listDatas = response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    handleClick(tab) {
      this.paramData.type = tab.props.name;
      this.currentPage = 1;
      this.getNewsData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getNewsData();
    },
  },
};
</script>
<style lang="scss">
.news-list {
  .el-card__body {
    padding: 0;
  }
  .el-card {
    border-radius: 10px;
  }
}
</style>
<style lang="scss" module>
@import 'index.scss';
</style>
