<template>
  <div :class="$style['tabs']">
    <div class="nav wrapper">
      <el-tabs v-model="activeName">
        <el-tabs v-model="activeName">
          <el-tab-pane
            :label="item.label"
            :name="item.name"
            v-for="item in navData"
            v-bind:key="item.name"
          ></el-tab-pane>
        </el-tabs>
      </el-tabs>
      <el-dropdown :class="$style['dropdown']">
        <el-button type="primary"> 开始创作<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="clickCreate('scratch')">图形化</el-dropdown-item>
            <el-dropdown-item @click="clickCreate('python')">Python</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
  <div class="bg">
    <div class="wrapper">
      <el-card class="filter-box ">
        <div class="filter-items clearfix">
          <div class="label">作品类型：</div>
          <el-radio-group v-model="radioValue" class="filter-item" size="small" @change="radioChangeHandle">
            <el-radio-button label="0">全部</el-radio-button>
            <el-radio-button label="2">图形化</el-radio-button>
            <el-radio-button label="3">Python</el-radio-button>
          </el-radio-group>
        </div>
      </el-card>
      <div class="list-box" :class="$style['create-list']">
        <el-row
          class="list"
          :class="$style['list']"
          :gutter="30"
          v-if="listDatas.records && listDatas.records.length > 0"
        >
          <el-col :span="30" v-for="o in listDatas.records" :key="o">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <img
                :src="
                  o.coverFileUrl
                    ? cdnUrl + o.coverFileUrl
                    : o.workType === '3'
                    ? 'https://cdn.1717youxue.com/static/site/pyeditor/image/pythonPic.jpg'
                    : empty
                "
                class="image"
                :onerror="`onerror=null;src='${empty}'`"
                @click="editCode"
                :data-workType="o.workType"
                :data-workFileUrl="o.workFileUrl"
              />
              <div class="txt-box">
                <span class="title">{{ o.workName }}</span>
                <p class="list-msg ">
                  <span class="msg-left">{{ o.workType === '2' ? '图形化' : 'Python' }}</span
                  ><span class="msg-right">{{ $moment(o.createTime).format('YYYY/MM/DD') }}</span>
                </p>
                <div class="bottom-btn">
                  <span @click="openDeleteDatas" :data-id="o.id"
                    ><svg-icon icon-class="icondelete" class="list-delete"></svg-icon>删除</span
                  >
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-empty description="暂无数据" v-else></el-empty>
      </div>
      <el-pagination
        @current-change="handleCurrentChange"
        v-model:currentPage="pageNo"
        background
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="listDatas.total"
        class="page-item"
        v-if="listDatas.records && listDatas.records.length > 0"
      >
      </el-pagination>
    </div>
    <el-dialog title="删除确认" v-model="dialogVisible" width="300px" :before-close="handleDeleteClose">
      <span>请确认是否要删除作品？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleDeleteClose">取 消</el-button>
          <el-button type="primary" @click="deleteDatas">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ElMessage } from 'element-plus';
import { CDNURL, PROGRAMURL } from '@/utils/constants';
import empty from '@/assets/img/empty.png';
export default {
  data() {
    return {
      // tabs 选中
      activeName: '1',
      // tabs 数据
      navData: [{ label: '我的作品', name: '1' }],
      // 类型 选中
      radioValue: '0',
      // 分页选中
      pageNo: 1,
      // 列表数据
      listDatas: {},
      // 删除弹窗
      dialogVisible: false,
      // 删除id
      deleteId: '',
      cdnUrl: CDNURL,
      programUrl: PROGRAMURL,
      // 空图片
      empty: empty,
    };
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    // 获取数据
    getDatas() {
      const that = this;
      this.$request
        .get(`teachingWork/mine?type=${this.radioValue}&pageNo=${this.pageNo}`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          that.listDatas = { ...data };
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 打开删除弹窗
    openDeleteDatas(e) {
      const { dataset = {} } = e.target || {};
      this.deleteId = dataset.id;
      this.dialogVisible = true;
    },
    // 编辑代码
    editCode(e) {
      const { dataset = {} } = e.target || {};
      const token = localStorage.getItem('token');
      let langName = 'scratch';
      if (dataset.worktype === '3') {
        langName = 'python';
      }
      const url = `${PROGRAMURL}${langName}/?token=${token}&codeUrl=${dataset.workfileurl}`;
      window.open(url, '_blank');
    },
    handleDeleteClose() {
      this.dialogVisible = false;
      this.deleteId = '';
    },
    // 删除数据
    deleteDatas() {
      const that = this;
      this.$request
        .delete(`teachingWork/delete?id=${this.deleteId}`)
        .then(function() {
          ElMessage.success({
            message: '删除成功',
            type: 'success',
          });
          that.dialogVisible = false;
          that.deleteId = '';
          that.getDatas();
        })
        .catch(function() {
          that.dialogVisible = false;
        });
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getDatas();
    },
    handleTabClick(tab) {
      this.activeName = tab.props.name;
      this.pageNo = 1;
      this.getDatas();
    },
    radioChangeHandle() {
      this.pageNo = 1;
      this.getDatas();
    },
    clickCreate(langName) {
      const token = localStorage.getItem('token');
      const url = `${PROGRAMURL}${langName}/?token=${token}&isCreate=1`;

      window.open(url);
    },
  },
};
</script>
<style lang="scss" module>
@import 'index.scss';
</style>
