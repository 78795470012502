
import QuestionDetailHead from '@/components/panels/QuestionDetailHead.vue';
import AnswerTopTitle from '@/components/panels/AnswerTopTitle.vue';
import AnswerHead from '@/components/panels/AnswerHead.vue';
import AnswerAnalysis from '@/components/panels/AnswerAnalysis.vue';
import CommitHistory from '@/components/panels/CommitHistory.vue';
import AnswerPageFoot from '@/components/panels/AnswerPageFoot.vue';

import { Options, Vue } from 'vue-class-component';
import { ElMessage } from 'element-plus';
import { PROGRAMURL } from '@/utils/constants';

@Options({
  props: {
    isSingQuesMod: Boolean, // 是否是单题目模式（题库）
    fixed: Boolean,
    isExam: Boolean,
    title: String,
    questionTypeName: String,
    time: String,
    analysisData: Object,
    historyData: Object,
    showAnalysis: Boolean,
    showHistory: Boolean,
    quesData: Object,
    onChange: Function,
    onClose: Function,
    onViewAnalysis: Function,
    onViewHistory: Function,
    onRandom: Function,
    onPrev: Function,
    onNext: Function,
    onDeleteCommitHistory: Function,
    onSave: Function,
    lang: Number,
  },
  components: {
    AnswerTopTitle,
    QuestionDetailHead,
    AnswerHead,
    AnswerAnalysis,
    CommitHistory,
    AnswerPageFoot,
  },
})
export default class Program extends Vue {
  $refs: ObjectT = {};
  $store: any;
  time!: string;
  $confirm: any;
  quesData: ObjectT = {};
  onChange!: () => {};
  onClose!: () => {};
  onRandom!: () => {};
  onPrev!: () => {};
  onNext!: () => {};
  onSave!: (type: number, totalSort: number, value: string) => {};
  pageBodyHeight = 0;
  showQuestionContent = true; // 是否显示题目
  lang!: number; // 2=scratch,3=python
  langName = '';
  isSave = false;
  token = localStorage.getItem('token');
  PROGRAMURL = PROGRAMURL;
  loading = false;

  mounted() {
    this.setLangName();
    this.calcPageBodyHeight();
    window.addEventListener('resize', () => {
      this.calcPageBodyHeight();
    });
  }

  // 设置编程题类型名称
  setLangName() {
    switch (this.quesData.lang) {
      case 2:
        this.langName = 'scratch';
        break;
      case 3:
        this.langName = 'python';
        break;
      default:
        break;
    }
  }

  // 计算主体页面高度
  calcPageBodyHeight() {
    const pageHead: any = this.$refs.pageHead;
    const pageFoot: any = this.$refs.pageFoot ? this.$refs.pageFoot.$el : null;
    const pageHeadH = pageHead ? pageHead.getBoundingClientRect().height : 0;
    const pageFootH = pageFoot ? pageFoot.getBoundingClientRect().height : 0;
    this.pageBodyHeight = document.body.clientHeight - pageHeadH - pageFootH - 18;
  }

  // 处理面板关闭
  handleClose() {
    this.handleSave(this.onClose);
  }

  // 处理题目内容显示
  handleViewQuestion() {
    this.showQuestionContent = !this.showQuestionContent;
  }

  // 处理下一题
  // 处理点击随机一题
  handleRandomQuestion() {
    if (this.onRandom) {
      this.onRandom();
    }
    this.showQuestionContent = true;
  }
  // 处理点击上一题
  handlePrevQuestion() {
    if (this.onPrev) {
      this.onPrev();
    }
    this.showQuestionContent = true;
  }
  // 处理点击下一题
  handleNextQuestion() {
    if (this.onNext) {
      this.onNext();
    }
    this.showQuestionContent = true;
  }

  // 处理保存
  handleSave(successCb: () => {}) {
    this.loading = true;
    this.$refs.iframe.contentWindow.uploadCode((data: ObjectT) => {
        this.loading = false;
        if (data.code === 10002) {
          ElMessage.error(data.msg ? data.msg : '保存失败');
          this.$store.commit('setLoginVisible', true);
          return;
        }
        if (data.code || data.code === 10003) {
          ElMessage.error(data.msg ? data.msg : '上传代码失败，请点击“文件”->“保存到电脑”保存代码，刷新页面后点击“文件”->“从电脑中打开”加载代码后重试！');
          return;
        }
        if (data.code || data.code === 10005) {
          ElMessage.error(data.msg ? data.msg : '上传代码失败，请先保存代码到本地后刷新页面重试！');
          return;
        }
        this.isSave = true;
        if (successCb instanceof Function) {
          successCb();
        } else {
          ElMessage.success(data.msg ? data.msg : '保存成功');
        }
        if (this.onSave) {
          this.onSave(5, this.quesData.totalSort, data.data);
        }
    });
  }
}
