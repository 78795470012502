<template>
  <div class="bg-color">
    <div class="select-box">
      <div class="wrapper">
        <el-card class="box-card">
          <div class="box-line clearfix">
            <div class="text">课程类型:</div>
            <div class="con">
              <el-button
                v-for="(item, index) in courseConList"
                :key="item.id"
                type="text"
                :class="index === courseType ? 'active' : ''"
                @click="handlerQuery(1, item.id)"
              >
                {{ item.btnName }}
              </el-button>
            </div>
          </div>
          <div class="box-line clearfix">
            <div class="text">课程形式:</div>
            <div class="con">
              <el-button
                v-for="(item, index) in kindTypeList"
                :key="item.id"
                type="text"
                :class="index === kindType ? 'active' : ''"
                @click="handlerQuery(2, item.id)"
              >
                {{ item.btnName }}
              </el-button>
            </div>
          </div>
          <div class="box-line clearfix">
            <div class="text">排序:</div>
            <div class="con">
              <el-button
                v-for="(item, index) in sortTypeList"
                :key="item.id"
                type="text"
                :class="index === sortType ? 'active' : ''"
                @click="handlerQuery(3, item.id)"
              >
                {{ item.btnName }}
              </el-button>
            </div>
          </div>
          <div class="box-line nopadding clearfix">
            <div class="text">关键字:</div>
            <div class="con noborder">
              <el-input
                placeholder="请输入关键词"
                v-model="courseName"
                size="small"
                class="search"
                @keydown.enter="handleIconClick"
              >
                <template #suffix> <i class="el-icon-search el-input__icon" @click="handleIconClick"> </i> </template
              ></el-input>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div class="list-box">
      <div class="wrapper">
        <div>
          <el-row class="list" :gutter="30" v-if="listDatas.records && listDatas.records.length > 0">
            <el-col :span="30" v-for="o in listDatas.records" :key="o">
              <el-card :body-style="{ padding: '0px' }" class="card" @click="toCourseDetail(o.id)">
                <img :src="o.pic ? o.pic : empty" class="image" :onerror="`onerror=null;src='${empty}'`" />
                <div style="padding: 14px">
                  <span>{{ o.courseName }}</span>
                  <el-rate
                    v-model="o.difficulty"
                    allow-half
                    disabled
                    disabled-void-color="#161c2d"
                    class="star-items"
                    :colors="['#ffe074', '#ffe074', '#ffe074']"
                  />
                  <div class="bottom">
                    <span><svg-icon icon-class="iconperson" class="list-icon"></svg-icon>{{ o.studyNum }}人</span>
                    <span><svg-icon icon-class="icontime" class="list-icon"></svg-icon>{{ o.lessonNum }}课次</span>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
          <el-empty description="暂无数据" v-else></el-empty>
          <div class="pagination">
            <el-pagination
              @current-change="handleCurrentChange"
              v-model:currentPage="pageNo"
              background
              :page-size="10"
              layout="prev, pager, next, jumper"
              :total="listDatas.total"
              class="page-item"
              v-if="listDatas.records && listDatas.records.length > 0"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import empty from '@/assets/img/empty.png';
import { CDNURL } from '@/utils/constants';
export default {
  data() {
    return {
      courseType: 0,
      kindType: 0,
      sortType: 0,
      courseName: '',
      pageNo: 1,
      listDatas: {},
      courseConList: [
        { id: 0, btnName: '全部' },
        { id: 1, btnName: '图形化' },
        { id: 2, btnName: 'Python' },
        { id: 3, btnName: '机器人' },
        { id: 4, btnName: '信息科技' },
        { id: 5, btnName: '启迪义教' },
      ],
      kindTypeList: [
        { id: 0, btnName: '全部' },
        { id: 1, btnName: '直播课' },
        { id: 2, btnName: '视频课' },
        { id: 3, btnName: '双师课' },
      ],
      sortTypeList: [
        { id: 0, btnName: '全部' },
        { id: 1, btnName: '学习人数' },
        { id: 2, btnName: '课程难度' },
      ],
      empty: empty,
    };
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    // 获取列表信息
    getDatas: function() {
      const that = this;
      const courseType = this.courseType === 0 ? this.courseType : this.courseType + 1;
      this.$request
        .get(
          `/studyCourse/courseList?courseType=${courseType}&kindType=${this.kindType}&sortType=${this.sortType}&courseName=${this.courseName}&pageNo=${this.pageNo}`,
        )
        .then(function(response) {
          const { data = {} } = response.data || {};
          let courseData = { ...data };
          for (let k in courseData.records) {
            courseData.records[k].pic = CDNURL + courseData.records[k].pic;
          }
          that.listDatas = courseData;
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    // 分页操作
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getDatas();
    },

    // 条件筛选
    handlerQuery(line, id) {
      if (line === 1) {
        this.courseType = id;
      }

      if (line === 2) {
        this.kindType = id;
      }

      if (line === 3) {
        this.sortType = id;
      }

      this.getDatas();
    },

    // 输入关键字时搜索
    handleIconClick() {
      this.pageNo = 1;
      this.getDatas();
    },
    // 跳转到详情页
    toCourseDetail(id) {
      this.$router.push('/course/' + id);
    },
  },
};
</script>
<style lang="scss">
.select-box {
  .el-card {
    padding: 3px 18px;
  }
  .el-button {
    padding: 0 20px;
    min-height: 30px;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
  }
  .box-line {
    .el-input {
      width: 295px;
      input {
        width: 295px;
        height: 28px;
        line-height: 28px;
        background-color: $bg-color-purle;
      }
    }
  }
}

.list-box {
  .list {
    margin-top: 60px;
    .el-rate__icon {
      margin: 0;
    }
    .bottom {
      color: #999;
      span {
        padding-right: 20px;
      }
      .list-icon {
        padding-right: 5px;
      }
    }
  }
  .card {
    transition: all 0.3s;
    transform: translateY(0);
    padding-bottom: 60px;
    .el-card {
      border-radius: 10px;
      .image {
        width: 100%;
        height: 135px;
        display: block;
      }
    }
    cursor: pointer;
    &:hover {
      transform: translateY(3px);
    }
  }
}

.pagination {
  text-align: right;
  padding-bottom: 50px;
}
</style>
<style lang="scss" scoped>
@import './index.scss';
</style>
