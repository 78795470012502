
import { Options, Vue } from 'vue-class-component';
import { PROGRAMURL } from '@/utils/constants';

@Options({
  props: {
    analysisData: Object,
    questionType: Number,
    lang: Number,
  },
  watch: {
    analysisData() {
      this.handleAnswer();
    },
  },
})
export default class Analysis extends Vue {
  analysisData: ObjectT = {};
  questionType = 0;
  answer!: any;
  lang!: number;
  token = localStorage.getItem('token');
  PROGRAMURL = PROGRAMURL;
  // 答案根据题目类似做处理
  handleAnswer() {
    const answer = this.analysisData.ans;
    switch (this.questionType) {
      case 1:
      case 4:
      case 5:
        this.answer = answer;
        break;
      case 2:
        this.answer = answer.split('|').join('');
        break;
      case 3:
        this.answer = answer.split('|');
        break;
      default:
        break;
    }
  }
  // 获取编程题类型名称
  getLangName() {
    let langName = '';
    switch (this.lang) {
      case 2:
        langName = '图形化';
        break;
      case 3:
        langName = 'python';
        break;
      default:
        break;
    }
    return langName;
  }
  // 查看代码
  handleViewCode(lang: number, ans: string) {
    const langName = this.getLangName();
    // const url = `${PROGRAMURL}${langName}/?token=${this.token}${ans ? `&codeUrl=${ans}` : ''}`;
    const url = `${PROGRAMURL}${langName}/?scene=view${ans ? `&codeUrl=${ans}` : ''}`;
    window.open(url, '_blank');
  }
}
